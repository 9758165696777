/** @format */

import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import Header from "../../components/website/header";
import { useEffect, useState } from "react";
import EmailPopup from "./emailPopup";
import Footer from "../../components/website/footer";
import useExitIntent from "../../hooks/useExitIntent";
import RequestQuote from "./requestQuote";
import { GoClock } from "react-icons/go";

import { LiaShippingFastSolid } from "react-icons/lia";
import { PiShippingContainerLight } from "react-icons/pi";
import { PiNewspaperClippingThin } from "react-icons/pi";

import { BsCurrencyDollar } from "react-icons/bs";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { PiSecurityCamera } from "react-icons/pi";

const faqs = [
    {
        question: "Online Arbitrage Prep",
        answer: "At our tax-free Delaware warehouse, we streamline the entire online arbitrage process for you. Once your packages arrive, we log their contents into our in-house dashboard, giving you real-time visibility into every shipment and order status. We take care of all aspects of Amazon FBA and FBM prep, ensuring your products are properly labeled, packaged, and fully compliant with Amazon’s requirements. From the moment we receive your inventory to the final step of fulfillment, we ensure everything is ready for fast, efficient shipping.",
    },
    {
        question: "Wholesale & Private Label Prep",
        answer: "Our tax-free Delaware warehouse is fully equipped to handle your wholesale and private label shipments, whether they arrive in pallets or individual packages. We log every shipment into our dedicated dashboard, giving you instant visibility into the status of each order. From receiving and processing to Amazon FBA and FBM prep, we ensure every item is carefully handled. As experts in brand partnerships, we offer custom packaging solutions tailored to your needs. We’re also experienced in managing large orders, including LTL shipments directly to Amazon warehouses, guaranteeing your products arrive on time and in perfect condition.",
    },
    {
        question: "Order Fulfillment for All Platforms",
        answer: "Whether you run an online store on Shopify, eBay, Walmart, or any platform outside of Amazon, our full-service fulfillment solutions are designed to support your business. We receive your products, log shipments for real-time tracking, and manage every step of order processing—from inventory management to packing and shipping. With our dedicated dashboard, you’ll have real-time access to monitor orders, shipments, and inventory status, keeping you fully in control. Our warehouse team ensures accurate, on-time deliveries, whether orders are going to customers or retail partners. No matter the size or complexity of your operations, we tailor our services to meet your unique fulfillment needs, providing smooth and efficient support for your business.",
    },
    // {
    //     question: "Return Management",
    //     answer: "Our return management service handles the entire process, ensuring your returned items are managed efficiently and with care. When a return arrives, we thoroughly inspect and categorize each item based on its condition. Sellable products are restocked into your inventory and prepared for immediate fulfillment, while unsellable items are processed and stored according to your instructions. If needed, we offer reshipping services for unsellable items, ensuring they reach the correct destination. Throughout the process, you have full visibility through our tracking system, keeping you informed every step of the way. Our goal is to minimize the hassle of returns and keep your operations running smoothly",
    // },
];

const elements = [
    { id: 1, name: "24 Hour Turnaround", icon: GoClock },
    {
        id: 3,
        name: "Price Matching",
        icon: BsCurrencyDollar,
    },
    { id: 2, name: "Dashboard Management", icon: HiMiniComputerDesktop },
    { id: 4, name: "24/7 Surveillance", icon: PiSecurityCamera },
];

function OrderFulfillmentPage() {
    const [showPopup, setShowPopup] = useState(false);
    const { isActive, disable } = useExitIntent({
        delayTime: 1000,
        initiallyDisabled: false,
    });

    useEffect(() => {
        if (isActive) {
            setShowPopup(true);
        }
    }, [isActive]);

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div>
            <Header />
            <div className="bg-white">
                <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-32 flex flex-col sm:flex-row">
                    <div className="mr-20 w-full">
                        <div className="lg:mr-10 w-full flex flex-col items-start">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Order Fulfillment Services
                            </p>
                            <div className="w-full">
                                <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                                    At Urva, we simplify fulfillment for
                                    ecommerce businesses by offering a smooth,
                                    reliable process. Whether you're shipping
                                    wholesale, private label, or online
                                    arbitrage products, we've got you covered.
                                    Our user-friendly dashboard lets you manage
                                    all your inbound and outbound shipments in
                                    one place. No matter where you sell, whether
                                    it's Amazon or another platform, we handle
                                    the logistics so you can focus on growing
                                    your business.
                                </p>

                                <div className="divide-y divide-gray-900/10 w-full">
                                    <div className="text-gray-600 grid grid-cols-4 sm:gap-x-0 gap-x-2 sm:grid-cols-4 justify-start pt-10 place-items-center sm:place-items-start">
                                        {elements.map((item) => (
                                            <div className="flex flex-col justify-center items-center max-w-12 sm:max-w-16">
                                                <item.icon className="sm:w-16 sm:h-16 w-10 h-10" />
                                                <span className=" text-xs sm:text-sm font-semibold text-center pt-2">
                                                    {item.name}
                                                </span>
                                            </div>
                                        ))}
                                    </div>

                                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                                        {faqs.map((faq) => (
                                            <Disclosure
                                                key={faq.question}
                                                as="div"
                                                className="pt-6"
                                            >
                                                <dt>
                                                    <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                                        <span className="text-base font-semibold leading-7">
                                                            {faq.question}
                                                        </span>
                                                        <span className="ml-6 flex h-7 items-center">
                                                            <PlusSmallIcon
                                                                aria-hidden="true"
                                                                className="h-6 w-6 group-data-[open]:hidden"
                                                            />
                                                            <MinusSmallIcon
                                                                aria-hidden="true"
                                                                className="h-6 w-6 [.group:not([data-open])_&]:hidden"
                                                            />
                                                        </span>
                                                    </DisclosureButton>
                                                </dt>
                                                <DisclosurePanel
                                                    as="dd"
                                                    className="mt-2 pr-12"
                                                >
                                                    <p className="text-base leading-7 text-gray-600">
                                                        {faq.answer}
                                                    </p>
                                                    <p
                                                        onClick={() =>
                                                            scrollToSection(
                                                                "request-quote"
                                                            )
                                                        }
                                                        className="cursor-pointer text-accent mt-2 font-medium"
                                                    >
                                                        Request a quote
                                                    </p>
                                                </DisclosurePanel>
                                            </Disclosure>
                                        ))}
                                    </dl>
                                    <div className="w-full bg-gray-900/200 mt-6"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img
                        className="object-cover w-full h-[30rem] rounded-lg mt-10 md:mt-0"
                        src="/img-boxes.jpeg"
                    ></img>
                </div>
            </div>

            <RequestQuote disable={disable} />
            <Footer />
            <EmailPopup show={showPopup} onClose={handleClosePopup} />
        </div>
    );
}

export default OrderFulfillmentPage;
