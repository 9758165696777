/** @format */

import React, { useEffect, useRef, useState } from "react";
import { GripVertical, ChevronDown } from "lucide-react";
import { Column } from "./models";

interface ColumnOrderDropdownProps {
    columnOrder: Column[];
    setColumnOrder: (columns: Column[]) => void;
    localStorageKey: string;
    selectedOrderType: {
        id: number;
        name: string;
        value: string;
    };
    initialColumnOrder: Column[];
    buttonHeightClass?: string;
}

const ColumnOrderDropdown: React.FC<ColumnOrderDropdownProps> = ({
    columnOrder,
    setColumnOrder,
    localStorageKey,
    selectedOrderType,
    initialColumnOrder,
    buttonHeightClass,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [draggedItem, setDraggedItem] = useState<number | null>(null);
    const [currentOrder, setCurrentOrder] = useState<Column[]>(columnOrder);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const persistedOrder = localStorage.getItem(localStorageKey);
        if (persistedOrder) {
            setColumnOrder(JSON.parse(persistedOrder));
            setCurrentOrder(JSON.parse(persistedOrder));
        }
    }, [selectedOrderType]);

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(currentOrder));
    }, [currentOrder]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const handleDragStart = (
        e: React.DragEvent<HTMLDivElement>,
        index: number
    ): void => {
        setDraggedItem(index);
        if (e.dataTransfer && e.currentTarget) {
            e.dataTransfer.effectAllowed = "move";
            e.dataTransfer.setData("text/plain", index.toString());
        }
    };

    const handleDragOver = (
        e: React.DragEvent<HTMLDivElement>,
        overIndex: number
    ): void => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";

        if (draggedItem === null) return;
        if (draggedItem === overIndex) return;

        // Update the visual order during drag
        setCurrentOrder((prevOrder) => {
            const newOrder = [...prevOrder];
            const [draggedColumn] = newOrder.splice(draggedItem, 1);
            newOrder.splice(overIndex, 0, draggedColumn);
            return newOrder;
        });
        setDraggedItem(overIndex);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        // Commit the visual order to the actual order
        setColumnOrder(currentOrder);
    };

    const handleDragEnd = (): void => {
        setDraggedItem(null);
    };

    const resetColumnOrder = () => {
        setCurrentOrder(initialColumnOrder);
        setColumnOrder(initialColumnOrder);
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className={`${
                    buttonHeightClass ? buttonHeightClass : "h-7"
                } flex items-center gap-2 px-4 text-xs border border-gray-300 shadow-sm rounded-md text-black hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-accent whitespace-nowrap`}
                type="button"
            >
                Column Order
                <ChevronDown className="w-4 h-4" />
            </button>

            {isOpen && (
                <div className="absolute z-30 mt-2 w-64 bg-white border border-gray-200 rounded-md shadow-lg right-0">
                    <div className="flex justify-center px-2 pt-2">
                        <button
                            onClick={() => resetColumnOrder()}
                            className="bg-accent text-white w-full rounded-md right-0 left-0 text-sm py-0.5 font-medium hover:bg-accenthighlight"
                        >
                            Reset Order
                        </button>
                    </div>
                    <div className="p-2">
                        {currentOrder.map((column, index) => (
                            <>
                                {!column.nonMovable && (
                                    <div
                                        key={column.key}
                                        draggable
                                        onDragStart={(e) =>
                                            handleDragStart(e, index)
                                        }
                                        onDragOver={(e) =>
                                            handleDragOver(e, index)
                                        }
                                        onDrop={handleDrop}
                                        onDragEnd={handleDragEnd}
                                        className={`flex items-center justify-between p-2 rounded cursor-move transition-colors duration-200
                                    ${
                                        draggedItem === index
                                            ? "opacity-50 bg-gray-50"
                                            : "hover:bg-gray-100"
                                    }
                                        ${
                                            draggedItem !== null &&
                                            draggedItem !== index
                                                ? "hover:border-blue-500"
                                                : ""
                                        }`}
                                    >
                                        <span className="text-xs text-gray-700">
                                            {column.label}
                                        </span>
                                        <GripVertical className="w-4 h-4 text-gray-400" />
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                    {/* <div className="flex justify-center px-2 pb-2">
                        <button
                            onClick={() => resetColumnOrder()}
                            className="bg-accent text-white w-full rounded-md right-0 left-0 text-sm py-0.5 font-medium hover:bg-accenthighlight"
                        >
                            Reset Order
                        </button>
                    </div> */}
                </div>
            )}
        </div>
    );
};

export default ColumnOrderDropdown;
