/** @format */

import { useEffect, useState } from "react";
import { HiMiniXMark } from "react-icons/hi2";
import Settings from "../settings";
import { MdOutlineInventory2 } from "react-icons/md";
import { TbBus } from "react-icons/tb";
import { PiShippingContainer } from "react-icons/pi";
import { LuLayoutDashboard } from "react-icons/lu";
import { HiOutlineCreditCard } from "react-icons/hi2";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { ChevronLeftIcon, CircleStackIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { IconType } from "react-icons";

type OpenItems = {
    [key: string]: boolean;
};

const navigation = [
    { name: "Dashboard", href: "/", icon: LuLayoutDashboard, current: true },
    {
        name: "Inbound",
        icon: TbBus,
        current: false,
        children: [
            { name: "Units", href: "/inbound/units" },
            { name: "Trackings", href: "/inbound/trackings" },
        ],
        href: "/inbound/units",
    },
    {
        name: "Inventory",
        icon: MdOutlineInventory2,
        current: false,
        children: [
            { name: "Units", href: "/inventory/units" },
            { name: "Trackings", href: "/inventory/trackings" },
        ],
        href: "/inventory/units",
    },
    {
        name: "Outbound",
        icon: PiShippingContainer,
        current: false,
        children: [
            { name: "Shipments", href: "/outbound/shipped" },
            { name: "Requested", href: "/outbound/requested" },
            { name: "Trackings", href: "/outbound/trackings" },
        ],
        href: "/outbound/shipped",
    },
    {
        name: "Billing",
        href: "/billing",
        icon: HiOutlineCreditCard,
        current: false,
    },
];

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

interface ClientMobileSideBarProps {
    hidden: boolean;
    toggleSidebar: () => void;
}

export default function ClientMobileSideBar({
    hidden,
    toggleSidebar,
}: ClientMobileSideBarProps) {
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        const normalizedPath = window.location.pathname.replace(/\/$/, "");
        var newNormalizedPath =
            normalizedPath.split("/")[0] +
            "/" +
            normalizedPath.split("/")[1] +
            "/" +
            normalizedPath.split("/")[2];
        setCurrentPath(newNormalizedPath);
    }, []);

    const [openItems, setOpenItems] = useState(() => {
        const saved = localStorage.getItem("sidebarOpenItems");
        return saved !== null ? JSON.parse(saved) : {};
    });

    useEffect(() => {
        // Save open items state to localStorage
        localStorage.setItem("sidebarOpenItems", JSON.stringify(openItems));
    }, [openItems]);

    const toggleItem = (itemName: string) => {
        setOpenItems((prevOpenItems: OpenItems) => ({
            ...prevOpenItems,
            [itemName]: !prevOpenItems[itemName], // Toggle the item's state
        }));
    };

    return (
        <div
            className={`flex-col items-start mb-5 bg-white fixed top-0 left-0 h-full z-40 transform ${
                hidden ? "-translate-x-full" : "translate-x-0"
            } transition-transform duration-300 rounded-r-xl`}
        >
            <div className="flex flex-row">
                {/* Apply fixed position */}
                <div className="fixed top-0 w-64 h-screen px-3 sm:px-9 pt-3 sm:pt-5 flex flex-col justify-between">
                    <div>
                        <button onClick={toggleSidebar}>
                            <HiMiniXMark className="h-7 w-7" />
                        </button>
                        <div className="pt-10"></div>
                        <ul role="list" className="space-y-1 z-50 mx-1">
                            {navigation.map((item) => (
                                <li key={item.name}>
                                    {!item.children ? (
                                        <a
                                            href={item.href}
                                            className={classNames(
                                                currentPath === item.href
                                                    ? "bg-gray-100 text-accent"
                                                    : "hover:bg-gray-100 text-gray-600",
                                                "group z-50 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 items-center"
                                            )}
                                        >
                                            <item.icon
                                                aria-hidden="true"
                                                className="h-6 w-6 shrink-0"
                                            />
                                            <span className="text-sm font-semibold">
                                                {item.name}
                                            </span>
                                        </a>
                                    ) : (
                                        <Disclosure as="div">
                                            <DisclosureButton
                                                onClick={() => {
                                                    toggleItem(item.name);
                                                    // navigate(item.href);
                                                }}
                                                className={classNames(
                                                    item.current
                                                        ? "bg-gray-100 text-accent"
                                                        : "hover:bg-gray-100 text-gray-600",
                                                    "group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6"
                                                )}
                                            >
                                                <item.icon
                                                    aria-hidden="true"
                                                    className="h-6 w-6 shrink-0"
                                                />
                                                {item.name}
                                                <ChevronRightIcon
                                                    aria-hidden="true"
                                                    className="ml-auto h-5 w-5 shrink-0 text-gray-700 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                                                />
                                            </DisclosureButton>
                                            {openItems[item.name] && (
                                                <ul className="mt-1 px-2">
                                                    {item.children.map(
                                                        (subItem) => (
                                                            <li
                                                                key={
                                                                    subItem.name
                                                                }
                                                            >
                                                                <DisclosureButton
                                                                    as="a"
                                                                    href={
                                                                        subItem.href
                                                                    }
                                                                    className={`
                                                                                ${
                                                                                    currentPath ===
                                                                                    subItem.href
                                                                                        ? "bg-gray-100"
                                                                                        : "hover:bg-gray-50"
                                                                                } block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700"
                                                                            `}
                                                                >
                                                                    {
                                                                        subItem.name
                                                                    }
                                                                </DisclosureButton>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            )}
                                        </Disclosure>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="pb-4">
                        <Settings />
                    </div>
                </div>
                {/* Add margin to content to account for the fixed sidebar */}
                <div className="ml-64">
                    <div className="h-screen fixed w-px bg-gray-300"></div>
                </div>
            </div>
        </div>
    );
}
