import Cookies from "js-cookie";

export const apiRequest = async (
    endpoint: string,
    method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
    body?: any
) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1${endpoint}`;

    try {
        const response = await fetch(url, {
            method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("jwt"),
            },
            body: body ? JSON.stringify(body) : undefined,
        });

        const data = await response.json();
        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error) } };
    }
};
