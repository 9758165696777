/** @format */

import { useEffect, useRef, useState } from "react";
import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import { fetchInventoryOrders } from "./../api/inventoryApi";
import Results from "../../../../models/results";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { Order } from "../../../../models/order";
import { useSearchParams } from "react-router-dom";
import { RequestOutboundShipmentModal } from ".././inventory-data/request-shipment/requestOutboundShipmentModal";
import { useSort } from "../../../../hooks/useSort";
import ClientSearchBar from "../../../../components/client/searchBar";
import { BulkEditInventoryOrdersModal } from "../inventory-data/bulkEditInventoryOrders";
import OrdersTable, { getTableHeaders } from "../../../../tables/ordersTable";
import LoadingWheel from "../../../../components/loadingWheel";
import { Column } from "../../../../tables/models";
import ColumnOrderDropdown from "../../../../tables/columnDragPopup";
import { orderTypes } from "../../../../models/data";
import { useClient } from "../../../../utils/client/clientContext";
import ConfirmRequestShipmentsModal from "./confirmRequestShipmentsModal";

function InventoryUnitsPage() {
    const { showNotification } = useNotification();
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const [searchParams] = useSearchParams();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(true);

    const [orders, setOrders] = useState<Order[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [shipmentFrequency, setShipmentFrequency] = useState("");

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const hasFetchedOrders = useRef(false);
    const fetchData = async () => {
        const data = await fetchInventoryOrders(searchParams, sortConfig);
        if (data.status === "success") {
            setOrders(data.data.inventory);
            setResults(data.data.results);
            setShipmentFrequency(data.data.fba_shipment_frequency);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
        setSelectedOrders([]);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [refetchData]);

    useEffect(() => {
        if (hasFetchedOrders.current) {
            setSortLoading(true);
            fetchData();
        }
    }, [sortConfig]);

    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
    const [
        openRequestOutboundShipmentModal,
        setOpenRequestOutboundShipmentModal,
    ] = useState(false);

    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);

    const [totalSum, setTotalSum] = useState("");

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[0]);
    const hiddenHeaders = [
        "input_shipping_quantity",
        "remove_selected_row",
        "input_shelf_location",
        "location",
    ];

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inbound")
    );

    const [
        openConfirmRequestShipmentsModal,
        setOpenConfirmRequestShipmentsModal,
    ] = useState(false);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto min-h-screen">
                    <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-row items-center">
                            <span className="text-2xl font-bold">
                                Inventory
                            </span>
                            <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                                {orderCount?.inventory}
                            </span>
                        </div>
                        <div className="space-x-4">
                            <button
                                disabled={selectedOrders.length < 1}
                                onClick={
                                    selectedOrders.length > 0 &&
                                    selectedOrders[0].fulfilled_by ===
                                        "merchant"
                                        ? () =>
                                              setOpenRequestOutboundShipmentModal(
                                                  true
                                              )
                                        : () =>
                                              showNotification(
                                                  "Do not select amazon fulfilled orders.",
                                                  "You can only select merchant for requests.",
                                                  "error"
                                              )
                                }
                                className={`${
                                    selectedOrders.length < 1
                                        ? "bg-accentdim"
                                        : "bg-accent hover:bg-accenthighlight"
                                } text-white font-medium px-5 text-sm rounded-md h-8`}
                            >
                                Request Outbound Shipment
                            </button>
                            {shipmentFrequency === "on_client_request" && (
                                <button
                                    onClick={() =>
                                        setOpenConfirmRequestShipmentsModal(
                                            true
                                        )
                                    }
                                    className={`text-white font-medium px-5 text-sm rounded-md h-8 bg-accent hover:bg-accenthighlight`}
                                >
                                    Request FBA Shipments
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-row items-start py-4 space-x-4">
                        <ClientSearchBar
                            setData={setOrders}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="inventory"
                            sub_page="units"
                        />
                        <ColumnOrderDropdown
                            columnOrder={unitsColumnOrder}
                            setColumnOrder={setUnitsColumnOrder}
                            localStorageKey="unitsColumnOrder"
                            selectedOrderType={selectedOrderType}
                            initialColumnOrder={getTableHeaders(
                                selectedOrderType.value
                            )}
                            buttonHeightClass="h-8"
                        />
                        <button
                            onClick={() => setOpenBulkEditModal(true)}
                            disabled={selectedOrders.length < 1}
                            className={`flex h-8 flex-row items-center text-xs px-4 shadow-sm rounded-md text-black ${
                                selectedOrders.length > 0
                                    ? "hover:bg-gray-50 bg-white border border-gray-400"
                                    : "bg-gray-50 border border-gray-200"
                            }`}
                        >
                            Edit
                        </button>
                    </div>

                    {!loading ? (
                        <OrdersTable
                            orderStatus="inventory"
                            orders={orders}
                            results={results}
                            selectedOrders={selectedOrders}
                            setSelectedOrders={setSelectedOrders}
                            sortLoading={sortLoading}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            handleRefetch={handleRefetch}
                            client_type="client"
                            setTotalSum={setTotalSum}
                            columnOrder={unitsColumnOrder}
                            hiddenHeaders={hiddenHeaders}
                        />
                    ) : (
                        <div className="flex flex-grow items-center pb-44 justify-center">
                            {loading ? (
                                <LoadingWheel />
                            ) : (
                                <span className="text-sm text-gray-500">
                                    User has no inbound orders
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <RequestOutboundShipmentModal
                selectedOrders={selectedOrders}
                openRequestOutboundShipmentModal={
                    openRequestOutboundShipmentModal
                }
                setOpenRequestOutboundShipmentModal={
                    setOpenRequestOutboundShipmentModal
                }
                handleRefetch={handleRefetch}
            />

            <BulkEditInventoryOrdersModal
                orderIds={selectedOrders.map((order) => order.order_id)}
                openEditModal={openBulkEditModal}
                setOpenEditModal={setOpenBulkEditModal}
                handleRefetch={handleRefetch}
            />

            <ConfirmRequestShipmentsModal
                openConfirmRequestShipmentsModal={
                    openConfirmRequestShipmentsModal
                }
                setOpenConfirmRequestShipmentsModal={
                    setOpenConfirmRequestShipmentsModal
                }
            />
        </div>
    );
}

export default InventoryUnitsPage;
