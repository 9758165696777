/** @format */

import { useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import LoadingWheel from "../../../../components/loadingWheel";
import Results from "../../../../models/results";
import { Order } from "../../../../models/order";
import { useSort } from "../../../../hooks/useSort";
import { fetchInboundOrders } from "../api/inboundApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { PlusIcon } from "@heroicons/react/24/outline";
import { MdOutlineFileUpload } from "react-icons/md";
import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import { AddBulkOrderModal, AddOrderModal } from "../addOrderModal";
import ClientSearchBar from "../../../../components/client/searchBar";
import DeleteOrderModal from "../inbound-data/deleteOrderModal";
import { BulkEditOrderModal } from "../inbound-data/bulkEditOrderModal";
import OrdersTable, { getTableHeaders } from "../../../../tables/ordersTable";
import { Column } from "../../../../tables/models";
import ColumnOrderDropdown from "../../../../tables/columnDragPopup";
import { orderTypes } from "../../../../models/data";
import { useClient } from "../../../../utils/client/clientContext";

export default function InboundUnitsPage() {
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();

    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);

    const [selectedInboundOrder, setSelectedInboundOrder] =
        useState<Order | null>(null);

    useEffect(() => {
        if (selectedInboundOrder) {
            setOpenEditModal(true);
        }
    }, [selectedInboundOrder]);

    useEffect(() => {
        if (!openEditModal) {
            setSelectedInboundOrder(null);
        }
    }, [openEditModal]);

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [openAddOrderModal, setOpenAddOrderModal] = useState(false);
    const [openAddBulkOrderModal, setOpenAddBulkOrderModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState<Order[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const hasFetchedOrders = useRef(false);
    const fetchData = async () => {
        const data = await fetchInboundOrders(searchParams, sortConfig);
        if (data.status === "success") {
            setOrders(data.data.inbound);
            setResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
        setSelectedOrders([]);
        hasFetchedOrders.current = true;
    };

    useEffect(() => {
        if (!hasFetchedOrders.current || refetchData != 0) {
            setLoading(true);
            fetchData();
        }
    }, [refetchData]);

    useEffect(() => {
        if (hasFetchedOrders.current) {
            setSortLoading(true);
            fetchData();
        }
    }, [sortConfig]);

    const [totalSum, setTotalSum] = useState("");

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[0]);
    const hiddenHeaders = [
        "input_shipping_quantity",
        "remove_selected_row",
        "input_shelf_location",
        "location",
    ];

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inbound")
    );

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto pb-4">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto min-h-screen">
                    <div className="flex flex-col sm:flex-row justify-between sm:items-center">
                        <div className="flex flex-row items-center">
                            <span className="text-2xl font-bold">Inbound</span>
                            <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                                {orderCount?.inbound}
                            </span>
                        </div>
                        <div className="flex flex-row items-center space-x-4 pt-4 sm:pt-0">
                            {/* <button
                                onClick={() => setOpenAddOrderModal(true)}
                                className={`h-7 flex items-center gap-2 px-4 text-xs bg-accent shadow-md rounded-md text-white hover:text-accenthighlight focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-accent whitespace-nowrap`}
                                type="button"
                            >
                                <PlusIcon className="w-4 h-4" />
                                New Inbound Shipment
                            </button>
                            <button
                                onClick={() => setOpenAddOrderModal(true)}
                                className={`h-7 flex items-center gap-2 px-4 text-xs border border-gray-300 shadow-sm rounded-md text-black hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-accent whitespace-nowrap`}
                                type="button"
                            >
                                <MdOutlineFileUpload className="w-4 h-4" />
                                Upload CSV
                            </button> */}
                            <button
                                onClick={() => setOpenAddOrderModal(true)}
                                className="bg-accent flex flex-row items-center text-white font-medium px-5 text-sm rounded-md h-8 hover:bg-accenthighlight"
                            >
                                <PlusIcon className="w-5 h-5" />
                                <span className="pl-2 text-sm">
                                    New Inbound Shipment
                                </span>
                            </button>
                            <button
                                onClick={() => setOpenAddBulkOrderModal(true)}
                                className="flex flex-row items-center border border-gray-300 hover:border-gray-300 rounded-md px-2 h-8 hover:bg-gray-50"
                            >
                                <MdOutlineFileUpload />
                                <span className="pl-2 text-sm">Upload CSV</span>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row items-start py-4 space-x-4">
                        <ClientSearchBar
                            setData={setOrders}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="inbound"
                            sub_page="units"
                        />
                        <ColumnOrderDropdown
                            columnOrder={unitsColumnOrder}
                            setColumnOrder={setUnitsColumnOrder}
                            localStorageKey="unitsColumnOrder"
                            selectedOrderType={selectedOrderType}
                            initialColumnOrder={getTableHeaders(
                                selectedOrderType.value
                            )}
                            buttonHeightClass="h-8"
                        />
                        <button
                            onClick={() => setOpenBulkEditModal(true)}
                            disabled={selectedOrders.length < 1}
                            className={`flex h-8 flex-row items-center text-xs px-4 shadow-sm rounded-md text-black ${
                                selectedOrders.length > 0
                                    ? "hover:bg-gray-50 bg-white border border-gray-400"
                                    : "bg-gray-50 border border-gray-200"
                            }`}
                        >
                            Edit
                        </button>
                        <button
                            disabled={selectedOrders.length < 1}
                            onClick={() => setOpenDeleteModal(true)}
                            className={`rounded-md text-sm text-white px-4 h-8 ${
                                selectedOrders.length > 0
                                    ? "hover:bg-red-500 bg-red-600"
                                    : "bg-red-300"
                            }`}
                        >
                            Delete
                        </button>
                    </div>
                    {!loading ? (
                        <OrdersTable
                            orderStatus="inbound"
                            orders={orders}
                            results={results}
                            selectedOrders={selectedOrders}
                            setSelectedOrders={setSelectedOrders}
                            sortLoading={sortLoading}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            handleRefetch={handleRefetch}
                            client_type="client"
                            setTotalSum={setTotalSum}
                            columnOrder={unitsColumnOrder}
                            hiddenHeaders={hiddenHeaders}
                        />
                    ) : (
                        <div className="flex flex-grow items-center justify-center">
                            {loading ? (
                                <LoadingWheel />
                            ) : (
                                <span className="text-sm text-gray-500">
                                    User has no inbound orders
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <AddOrderModal
                openAddOrderModal={openAddOrderModal}
                setOpenAddOrderModal={setOpenAddOrderModal}
                handleRefetch={handleRefetch}
            />

            <AddBulkOrderModal
                openAddBulkOrderModal={openAddBulkOrderModal}
                setOpenAddBulkOrderModal={setOpenAddBulkOrderModal}
                handleRefetch={handleRefetch}
            />

            <DeleteOrderModal
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                order_ids={selectedOrders.map((order) => order.order_id)}
                setOpenEditModal={setOpenEditModal}
                handleRefetch={handleRefetch}
                setSelectedOrders={setSelectedOrders}
            />

            <BulkEditOrderModal
                orderIds={selectedOrders.map((order) => order.order_id)}
                openEditModal={openBulkEditModal}
                setOpenEditModal={setOpenBulkEditModal}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}
