/** @format */

import { useEffect, useState } from "react";
import DashboardFeatures from "../../components/website/dashboardFeatures";
import Footer from "../../components/website/footer";
import Header from "../../components/website/header";
import Services from "../../components/website/services";
import EmailPopup from "./emailPopup";
import RequestQuotePage from "./requestQuote";
import { PiPackageLight } from "react-icons/pi";
import { LiaShippingFastSolid } from "react-icons/lia";
import { PiShippingContainerLight } from "react-icons/pi";
import { PiNewspaperClippingThin } from "react-icons/pi";
import useExitIntent from "../../hooks/useExitIntent";
import { CiCreditCard1 } from "react-icons/ci";

const homeElements = [
    { id: 1, name: "Order Fulfillment", icon: PiPackageLight },
    { id: 3, name: "FBA Preparation", icon: PiShippingContainerLight },
    { id: 2, name: "Reshipping Services", icon: LiaShippingFastSolid },
    { id: 4, name: "Tax-Free Purchaing", icon: CiCreditCard1 },
];

export default function HomePage() {
    const [showPopup, setShowPopup] = useState(false);
    const { isActive, disable } = useExitIntent({
        delayTime: 1000,
        initiallyDisabled: false,
    });

    useEffect(() => {
        if (isActive) {
            setShowPopup(true);
        }
    }, [isActive]);

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div className="scroll-smooth">
            <img
                alt=""
                src="/homebg.png"
                className="absolute inset-0 -z-10 h-full w-full object-cover"
            />
            <div className="absolute inset-0 bg-black opacity-55"></div>
            <Header />
            <div className="absolute bottom-36" id="learn"></div>
            <div>
                <div className="relative isolate px-6 lg:px-8 h-screen flex items-center">
                    <div className="mx-auto max-w-2xl">
                        <div className="text-center">
                            <h1 className="text-3xl font-bold tracking-tight text-white sm:text-6xl">
                                Focus on your{" "}
                                <span className="text-accent">business</span>{" "}
                                while we handle the rest
                            </h1>
                            <div className="text-gray-300 grid grid-cols-4 sm:gap-x-0 gap-x-2 sm:grid-cols-4 justify-center pt-10 place-items-center">
                                {homeElements.map((item) => (
                                    <div className="flex flex-col justify-center items-center max-w-12 sm:max-w-16">
                                        <item.icon className="sm:w-16 sm:h-16 w-10 h-10" />
                                        <span className=" text-xs sm:text-sm font-semibold">
                                            {item.name}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <button
                                    onClick={() =>
                                        scrollToSection("request-quote")
                                    }
                                    className="rounded-md bg-accent px-3.5
                                    py-2.5 text-sm font-semibold text-white
                                    shadow-sm hover:bg-accenthighlight"
                                >
                                    Get Started
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="learn"></div>
            <Services />
            <DashboardFeatures />

            <div id="request-quote"></div>
            <RequestQuotePage disable={disable} />
            <Footer />

            <EmailPopup show={showPopup} onClose={handleClosePopup} />
        </div>
    );
}
