/** @format */

import { useState } from "react";
import Footer from "../../components/website/footer";
import Header from "../../components/website/header";
import RequestQuote from "./requestQuote";

const onlineArbitrageCards = [
    {
        id: 1,
        label: "Online Arbitrage",
        units: [
            { label: "0-499 units/month", pricing: "$1.60" },
            { label: "500-999 units/month", pricing: "$1.50" },
            { label: "1,000-1,999 units/month", pricing: "$1.20" },
            { label: "2,000+ units/month", pricing: "$1.00" },
        ],
        sub_label:
            "Pricing includes prep, labeling, and packaging for all items sourced from online retailers",
        lowest: "As low as $1.00/unit",
    },
    {
        id: 2,
        label: "Wholesale",
        units: [
            { label: "0-499 units/month", pricing: "$1.00" },
            { label: "500-999 units/month", pricing: "$0.90" },
            { label: "1,000-1,999 units/month", pricing: "$0.70" },
            { label: "2,000+ units/month", pricing: "$0.50" },
        ],
        sub_label:
            "Pricing includes prep, labeling, and packaging for all items sourced from wholesale suppliers",
        lowest: "As low as $0.50/unit",
    },
    {
        id: 3,
        label: "Reshipping",
        units: [],
        sub_label:
            "Reshipping prices vary depending on the specifications, contact us to get a qoute!",
        sub_label2:
            "Save money on your purchases by shipping them to one of our tax-free Delaware warehouses, and we'll reship them directly to you.",
        lowest: "Pricing starting at $0.50/unit",
    },
];

const isMobile = () => {
    if (typeof window !== "undefined") {
        return window.innerWidth < 640; // Mobile breakpoint for Tailwind (sm)
    }
    return false; // Default to false if window is undefined (e.g., during SSR)
};

// Function to return the appropriate costs array
const getAdditionalCosts = () => {
    const additionalCostsDesktop = [
        { label: "Sneakers: +$0.50" },
        { label: "Storage: Free for 30 days" },
        { label: "Small Box (14xx11x7): $1.00" },
        { label: "Bubble Wrap: +$0.25" },
        { label: "Long-Term Storage: $30/month per pallet" },
        { label: "Medium Box (20x16x16): $2.00" },
        { label: "Multi Pack / Bundle: +$0.25" },
        { label: "Merchant Fulfillment: +$1.00" },
        { label: "XL Box (24x20x20): $3.50" },
    ];

    const additionalCostsMobile = [
        { label: "Sneakers: + $0.50" },
        { label: "Small Box (14xx11x7) : $1.00" },
        { label: "Bubble Wrap: +$0.25" },
        { label: "Medium Box (20x16x16): $2.00" },
        { label: "Multi Pack / Bundle: + $0.25" },
        { label: "XL Box (24x20x20): $3.50" },
        { label: "Storage: Free for 30 days" },
        { label: "Merchant Fulfillment: +$1.00" },
        { label: "Long-Term Storage: $30/month per pallet" },
    ];

    return isMobile() ? additionalCostsMobile : additionalCostsDesktop;
};

function PricingPage() {
    const [additionalCosts, setAdditionalCosts] = useState(
        getAdditionalCosts()
    );

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div>
            <Header />

            <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-32 flex flex-col">
                <div className="mr-20 w-full">
                    <div className="lg:mr-10 w-full flex flex-col items-start">
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                            Pricing
                        </p>
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-center sm:space-y-0 space-y-5 sm:space-x-14 pt-4 pb-4 h-full">
                    {onlineArbitrageCards.map((item) => (
                        <div
                            key={item.id}
                            className="justify-between border border-gray-200 shadow-sm w-full rounded-md py-4 px-3 flex flex-col h-72"
                        >
                            <div className="flex flex-col">
                                <span className="font-semibold">
                                    {item.label}
                                </span>
                                <div className="text-xs text-gray-600 pt-2">
                                    {item.sub_label2
                                        ? item.sub_label2
                                        : item.sub_label}
                                </div>
                                {item.units.length > 0 ? (
                                    <>
                                        {item.units.map((unit) => (
                                            <div className="flex flex-row items-center justify-between pt-2 text-sm">
                                                <span className="">
                                                    {unit.label}:
                                                </span>
                                                <span className="">
                                                    {unit.pricing}
                                                </span>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <span className="text-sm pt-2">
                                        {item.sub_label}
                                    </span>
                                )}
                            </div>
                            <div>
                                <div
                                    onClick={() =>
                                        scrollToSection("request-quote")
                                    }
                                    className="bg-accentbg px-3 py-2 rounded-md hover:bg-accentbg/70 cursor-pointer flex flex-row items-center justify-between"
                                >
                                    <span className="text-accent font-semibold text-sm">
                                        {item.lowest}
                                    </span>
                                    <span className="text-accent font-medium text-xs">
                                        Request a quote
                                    </span>
                                </div>
                                {/* <div
                                    onClick={() =>
                                        scrollToSection("request-quote")
                                    }
                                    className="cursor-pointer text-accent pt-1 text-sm"
                                >
                                    Request a quote
                                </div> */}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="border border-gray-200 shadow-sm w-full rounded-md py-4 px-3 flex flex-col">
                    <span className="font-semibold">Additional Fees</span>
                    <div className="grid grid-cols-2 sm:grid-cols-3 text-sm sm:gap-x-14 gap-y-2 sm:gap-y-4 pt-2">
                        {additionalCosts.map((item, index) => (
                            <span
                                key={index}
                                className={`text-2xs sm:text-sm ${
                                    index === additionalCosts.length - 1
                                        ? "col-span-2 sm:col-span-1"
                                        : ""
                                }`}
                            >
                                {item.label}
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            <div id="request-quote"></div>
            <RequestQuote />

            <Footer />
        </div>
    );
}

export default PricingPage;
