/** @format */

import { useSearchParams } from "react-router-dom";
import { TrackingArrived } from "../models/trackingArrived";
import { TrackingStatus } from "../models/order";
import { useState } from "react";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import DeleteTrackingModal from "../pages/admin/clients/client-data/trackings/deleteTrackingModal";
import { SortLoading } from "../components/loadingWheel";
import { TrashIcon } from "@heroicons/react/24/outline";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { Column } from "./models";

export const initialTrackingsColumnOrder = [
    {
        key: "created_at",
        label: "Date Sent",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "tracking",
        label: "Tracking number",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "carrier",
        label: "Detected Carrier",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "tracking_url",
        label: "URL",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "actions",
        label: "Actions",
        isSortable: false,
        className: "text-right",
    },
];

interface TrackingsTableProps {
    trackings: TrackingArrived[];
    results: Results;
    trackingType: TrackingStatus;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    emptyTableText: string;
    columnOrder?: Column[];
}

function TrackingsTable({
    trackings,
    results,
    trackingType,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    emptyTableText,
    columnOrder,
}: TrackingsTableProps) {
    const [searchParams] = useSearchParams();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTrackingId, setSelectedTrackingId] = useState<string | null>(
        ""
    );
    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    return (
        <div className="flex flex-col pt-3">
            {trackings && trackings.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {columnOrder &&
                                        columnOrder.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.label}
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {trackings.map(
                                    (
                                        tracking: TrackingArrived,
                                        index: number
                                    ) => (
                                        <tr
                                            key={index}
                                            className="relative border-t border-gray-200 text-sm text-gray-500"
                                        >
                                            {columnOrder &&
                                                columnOrder.map(
                                                    (header: Column) => (
                                                        <>
                                                            {header.key ===
                                                            "created_at" ? (
                                                                <td className="py-2.5 text-sm">
                                                                    {convertDateToEST(
                                                                        tracking[
                                                                            header.key as keyof TrackingArrived
                                                                        ]
                                                                    )}
                                                                </td>
                                                            ) : header.key ===
                                                              "actions" ? (
                                                                <>
                                                                    {trackingType ===
                                                                        "inventory" && (
                                                                        <td
                                                                            onClick={() =>
                                                                                setSelectedTrackingId(
                                                                                    tracking.tracking_id
                                                                                )
                                                                            }
                                                                            className="text-right whitespace-nowrap pr-2 cursor-pointer"
                                                                        >
                                                                            <TrashIcon className="w-5 h-5 text-red-500 inline-block" />
                                                                        </td>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <td
                                                                    key={`${index}-${header.key}`}
                                                                    className="py-2.5 text-sm"
                                                                >
                                                                    {
                                                                        tracking[
                                                                            header.key as keyof TrackingArrived
                                                                        ]
                                                                    }
                                                                </td>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/admin/${trackingType}/trackings?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/admin/${trackingType}/trackings?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        {emptyTableText}
                    </span>
                </div>
            )}

            <DeleteTrackingModal
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                id={selectedTrackingId}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default TrackingsTable;
