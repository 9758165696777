import dayjs, { Dayjs } from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const formatDateFromDb = (dateString: string): string => {
    return dayjs.utc(dateString).format('MM/DD/YYYY');
};

export const formatDateFromObject = (date: Dayjs): string => {
    return date.format('MM/DD/YYYY')
};

export const convertToDayjsFromString = (dateString: string): Dayjs => {
    return dayjs(dateString, 'YYYY/MM/DD');
};

// export const formatDateFromDb = (dateString: string): string => {
//     return dayjs(dateString).format('MM/DD/YYYY');
// };

export function convertDateToEST(timestamp: string) {
    const date = new Date(timestamp);
  
    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'America/New_York', // EST timezone
        year: 'numeric',              // 'numeric' instead of 'string'
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,                 // 12-hour format
      };
    
    // Get the formatted date string
    const formattedDate = date.toLocaleString('en-US', options);
  
    return formattedDate.replace(',', '');
  }