import Cookies from "js-cookie";
import { buildUrl } from "../../../utils/url/buildUrl";


export const fetchInvoices = async (
    user_id: string | undefined | null
) => {

    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/invoices`;
    const params = {
        user_id: user_id ? user_id : null
    };

    const url = buildUrl(baseUrl, params);

    try {
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
};

export const closeInvoicePeriod = async (
    user_id: string | undefined,
    invoice_id: string | undefined,
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/invoices/` + user_id,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({invoice_id})
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const fetchAdditionalCostsForInvoice = async (
    invoice_id: string | undefined,
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/invoices/additional-costs/` + invoice_id,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const addAdditionalCostToInvoice = async (
    invoice_id: string | undefined,
    title: string,
    description: string,
    total: string
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/invoices/additional-costs/` + invoice_id,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify( { title, description, total })
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const deleteAdditionalCostToInvoice = async (
    cost_id: string
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/invoices/additional-costs/` + cost_id,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

type PaymentStatus = "paid" | "unpaid";

export const editPastInvoice = async (
    user_id: string | undefined,
    invoice_id: string | undefined,
    invoice_url: string,
    payment_status: PaymentStatus
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/invoices/past/` + user_id,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({invoice_id, invoice_url, payment_status})
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const resendPastInvoice = async (
    invoice_id: string | undefined
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/invoices/resend/` + invoice_id,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                }
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const fetchDiscountsForInvoice = async (
    invoice_id: string | undefined,
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/invoices/discounts/` + invoice_id,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const addDiscountToInvoice = async (
    invoice_id: string | undefined,
    title: string,
    description: string,
    total: string
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/invoices/discounts/` + invoice_id,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify( { title, description, total })
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}

export const deleteDiscountToInvoice = async (
    cost_id: string
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/clients/invoices/discounts/` + cost_id,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return { data: { status: "failed", message: String(error)}}
    }
}