/** @format */

import { useState } from "react";
import { PaymentStatusTag } from "../../../components/statusTags";
import { Invoice } from "../../../models/invoices";
import Shipment from "../../../models/shipments";
import { formatDateFromDb } from "../../../utils/date/date_formatter";
import { formatMoney } from "../../../utils/price/formatMoney";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import ClientShipmentsTable from "../clients/client-data/shipments/client-orders-data/clientShipmentsTable";

export function OpenInvoice({
    openInvoice,
    openShipments,
}: {
    openInvoice: Invoice | null;
    openShipments: Shipment[];
}) {
    const tableHeaders = [
        {
            header: "Payment Status",
            value: <PaymentStatusTag status={openInvoice?.status || ""} />,
        },
        { header: "Invoice date", value: "None" },
        {
            header: "Invoice period",
            value:
                formatDateFromDb(openInvoice?.start_date || "") +
                " - " +
                getDate(),
        },
        { header: "Total saved", value: formatMoney(openInvoice?.total_saved) },
        { header: "Current total", value: formatMoney(openInvoice?.total) },
    ];

    const [showInvoice, setShowInvoice] = useState(false);

    function getDate() {
        const today = new Date();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const date = String(today.getDate()).padStart(2, "0");
        const year = today.getFullYear();
        return `${month}/${date}/${year}`;
    }

    return (
        <div className="flex flex-col pt-3">
            <div className="w-full h-px bg-gray-200"></div>
            <div className="flex flex-col-reverse sm:flex-row justify-between sm:items-center pt-7 pb-7">
                <div className="flex flex-row items-center text-sm">
                    <button
                        onClick={() => setShowInvoice(!showInvoice)}
                        className="pr-6"
                    >
                        {!showInvoice ? (
                            <ChevronDownIcon className="h-5 w-5 hover:text-accent" />
                        ) : (
                            <ChevronUpIcon className="h-5 w-5 hover:text-accent" />
                        )}
                    </button>
                    {tableHeaders.map((item) => (
                        <div className="flex flex-col space-y-3 min-w-52">
                            <span className="text-xs font-bold">
                                {item.header}
                            </span>
                            <span className="h-8">{item.value}</span>
                        </div>
                    ))}
                </div>
                <div className="flex flex-row items-center space-x-10">
                    <button
                        onClick={() => setShowInvoice(!showInvoice)}
                        className="text-sm font-semibold text-gray-600"
                    >
                        View
                    </button>
                </div>
            </div>
            {showInvoice ? (
                <ClientShipmentsTable shipments={openShipments} />
            ) : (
                <></>
            )}

            <div className="w-full h-px bg-gray-200"></div>
        </div>
    );
}
