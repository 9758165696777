/** @format */

import { useEffect, useRef, useState } from "react";
import { PencilSquareIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useNotification } from "../../../utils/notification/notificationContext";
import { useSearchParams } from "react-router-dom";
import { Order } from "../../../models/order";
import Results from "../../../models/results";
import { useSort } from "../../../hooks/useSort";
import { Invoice } from "../../../models/invoices";
import Shipment from "../../../models/shipments";
import { fetchInvoices } from "../api/billingApi";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import { ClientFilterPopup } from "../clients/filters/clientFilter";
import InvoicesTable from "../../../tables/invoicesTable";
import LoadingWheel from "../../../components/loadingWheel";
import CloseOpenInvoiceModal from "./modals/closeOpenInvoiceModal";
import AdditionalCostsModal from "./modals/additionalCostsModal";
import DiscountsModal from "./modals/discountsModal";
import EditPastInvoiceModal from "./modals/editPastInvoiceModal";
import { OpenInvoice } from "./openInvoice";
import { getAdminType } from "../../../utils/adminTypes/actions";
import { useClient } from "../../../utils/client/clientContext";
import ClientDrawer from "../clients/clientDrawer";
import { UserIcon } from "@heroicons/react/24/outline";

export default function AdminBillingPage() {
    const { showNotification } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const isOwner = getAdminType() === "owner";

    // const [user_id, setUserId] = useState(searchParams.get("user_id"));
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const [openInvoice, setOpenInvoice] = useState<Invoice | null>(null);
    const [openShipments, setOpenShipments] = useState<Shipment[]>([]);
    const [pastInvoices, setPastInvoices] = useState<Invoice[]>([]);

    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });
    const [loading, setLoading] = useState(false);

    const [refetchData, setRefetchData] = useState(false);
    const handleRefetch = () => {
        setRefetchData((prev) => !prev);
    };

    const fetchData = async () => {
        setOpenInvoice(null);
        const data = await fetchInvoices(user_id);
        if (data.status === "success") {
            setOpenInvoice(data.data.open_invoice);
            setPastInvoices(data.data.past_invoices);
            setOpenShipments(data.data.open_shipments);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (isOwner) {
            setLoading(true);
            fetchData();
        } else {
            showNotification(
                "Billing data can not be viewed by employees.",
                "",
                "error"
            );
        }
    }, [refetchData, user_id]);

    const clientFilterRef = useRef<HTMLButtonElement>(null);

    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

    const toggleFilter = () => {
        setIsFilterOpen((prevState) => !prevState);
    };

    useEffect(() => {
        setUserId(searchParams.get("user_id"));
    }, [searchParams]);

    const [selectedPastInvoice, setSelectedPastInvoice] =
        useState<Invoice | null>(null);

    const [openViewOpenInvoiceModal, setOpenViewOpenInvoiceModal] =
        useState(false);

    const [openAdditionalCostsModal, setOpenAdditionalCostsModal] =
        useState(false);
    const [openDiscountsModal, setOpenDiscountsModal] = useState(false);

    const [openEditPastInvoiceModal, setOpenEditPastInvoiceModal] =
        useState(false);

    const [clientDrawerOpen, setClientDrawerOpen] = useState(false);

    useEffect(() => {
        if (selectedPastInvoice) {
            setOpenEditPastInvoiceModal(true);
        }
    }, [selectedPastInvoice]);

    const [totalSum, setTotalSum] = useState("");

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <span className="text-2xl font-bold">Billing</span>

                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center pt-5 pb-2 space-x-4">
                        <div className="relative">
                            <button
                                ref={clientFilterRef}
                                onClick={toggleFilter}
                                className="text-xs text-gray-600 flex flex-row items-center rounded-md shadow-sm border border-gray-300 px-2 h-7 divide-x gap-x-2"
                            >
                                <div className="flex flex-row items-center space-x-2">
                                    {!user_id ? (
                                        <PencilSquareIcon className="w-4 h-4" />
                                    ) : (
                                        <XCircleIcon
                                            className="w-4 h-4 text-red-500"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                searchParams.delete("user_id");
                                                // Update the URL with the modified searchParams
                                                window.history.replaceState(
                                                    null,
                                                    "",
                                                    `?${searchParams.toString()}`
                                                );
                                                setUserId(null);
                                            }}
                                        />
                                    )}
                                    <span>Client ID</span>
                                </div>
                                {user_id && (
                                    <span className="font-medium text-accent pl-2">
                                        {user_id} -{" "}
                                        <span className="text-gray-600">
                                            {client &&
                                                client.first_name +
                                                    " " +
                                                    client.last_name}
                                        </span>
                                    </span>
                                )}
                            </button>
                            <ClientFilterPopup
                                isOpen={isFilterOpen}
                                onClose={() => setIsFilterOpen(false)}
                                taxButtonRef={clientFilterRef}
                                user_id={user_id}
                                setUserId={setUserId}
                            />
                        </div>
                        {user_id && (
                            <button
                                onClick={() => setClientDrawerOpen(true)}
                                className="flex h-7 flex-row items-center text-xs px-2 border border-gray-300 shadow-sm rounded-md text-black"
                            >
                                <UserIcon className="w-4 h-4" />{" "}
                                <span className="pl-1.5">View client</span>
                            </button>
                        )}
                    </div>
                    <div className="flex flex-row items-center">
                        {totalSum !== "" && (
                            <span className="text-gray-700 text-sm">
                                Sum: {totalSum}
                            </span>
                        )}
                    </div>
                </div>

                {!loading ? (
                    <>
                        {openInvoice ? (
                            <>
                                <div className="flex flex-col sm:flex-row justify-between pt-4">
                                    <span className="text-lg font-semibold">
                                        Current Invoice Tab
                                    </span>
                                    <div className="space-x-4 flex flex-row">
                                        <button
                                            onClick={() =>
                                                setOpenDiscountsModal(true)
                                            }
                                            className="flex flex-row text-sm items-center border border-gray-300 hover:border-gray-300 rounded-md px-2 h-8 hover:bg-gray-50"
                                        >
                                            Manage discounts
                                        </button>
                                        <button
                                            onClick={() =>
                                                setOpenAdditionalCostsModal(
                                                    true
                                                )
                                            }
                                            className="flex flex-row text-sm items-center border border-gray-300 hover:border-gray-300 rounded-md px-2 h-8 hover:bg-gray-50"
                                        >
                                            Manage additional costs
                                        </button>
                                        <button
                                            onClick={() =>
                                                setOpenViewOpenInvoiceModal(
                                                    true
                                                )
                                            }
                                            className="bg-accent text-white font-semibold text-sm px-3 h-8 rounded-md hover:bg-accenthighlight"
                                        >
                                            End period
                                        </button>
                                    </div>
                                </div>
                                <div className="pb-6">
                                    <OpenInvoice
                                        openInvoice={openInvoice}
                                        openShipments={openShipments}
                                    />
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        <InvoicesTable
                            invoices={pastInvoices}
                            results={results}
                            setSelectedPastInvoice={setSelectedPastInvoice}
                            setTotalSum={setTotalSum}
                        />
                    </>
                ) : (
                    <div className="flex flex-grow items-center pb-44 justify-center">
                        {loading ? (
                            <LoadingWheel />
                        ) : (
                            <span className="text-sm text-gray-500">
                                User has no inbound orders
                            </span>
                        )}
                    </div>
                )}
            </div>

            <CloseOpenInvoiceModal
                user_id={user_id || ""}
                openViewOpenInvoiceModal={openViewOpenInvoiceModal}
                setOpenViewOpenInvoiceModal={setOpenViewOpenInvoiceModal}
                invoice_id={openInvoice?.invoice_id}
                handleRefetch={handleRefetch}
            />

            <AdditionalCostsModal
                openAdditionalCostsModal={openAdditionalCostsModal}
                setOpenAdditionalCostsModal={setOpenAdditionalCostsModal}
                invoice_id={openInvoice?.invoice_id}
                handleRefetch={handleRefetch}
            />

            <DiscountsModal
                openDiscountsModal={openDiscountsModal}
                setOpenDiscountsModal={setOpenDiscountsModal}
                invoice_id={openInvoice?.invoice_id}
                handleRefetch={handleRefetch}
            />

            <EditPastInvoiceModal
                openEditPastInvoiceModal={openEditPastInvoiceModal}
                setOpenEditPastInvoiceModal={setOpenEditPastInvoiceModal}
                selectedPastInvoice={selectedPastInvoice}
                setSelectedPastInvoice={setSelectedPastInvoice}
                handleRefetch={handleRefetch}
            />

            <ClientDrawer
                user_id={user_id || ""}
                open={clientDrawerOpen}
                setOpen={setClientDrawerOpen}
                client={client}
                setClient={setClient}
            />
        </div>
    );
}
