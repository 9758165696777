/** @format */

import { useEffect, useRef, useState } from "react";
import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import Results from "../../../../models/results";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { TrackingArrived } from "../../../../models/trackingArrived";
import { fetchTrackings } from "./../api/trackingsApi";
import TrackingsArrivedTable from "./../tracking-data/trackingsTable";
import { useSearchParams } from "react-router-dom";
import { useSort } from "../../../../hooks/useSort";
import ClientSearchBar from "../../../../components/client/searchBar";
import TrackingsTable, {
    initialTrackingsColumnOrder,
} from "../../../../tables/trackingsTable";
import { Column } from "../../../../tables/models";
import LoadingWheel from "../../../../components/loadingWheel";
import ColumnOrderDropdown from "../../../../tables/columnDragPopup";
import { orderTypes } from "../../../../models/data";
import { useClient } from "../../../../utils/client/clientContext";

function InventoryTrackingsPage() {
    const { showNotification } = useNotification();
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const [searchParams] = useSearchParams();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(true);

    const [trackings, setTrackings] = useState<TrackingArrived[]>([]);
    const [trackingsResults, setTrackingsResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const hasFetchedTrackings = useRef(false);

    const fetchTrackingsData = async () => {
        const data = await fetchTrackings(searchParams, sortConfig);
        if (data.status === "success") {
            setTrackings(data.data.trackings);
            setTrackingsResults(data.data.results);
            hasFetchedTrackings.current = true;
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchTrackingsData();
    }, []);

    useEffect(() => {
        if (hasFetchedTrackings.current) {
            setSortLoading(true);
            fetchTrackingsData();
        }
    }, [sortConfig]);

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[1]);
    const [trackingsColumnOrder, setTrackingsColumnOrder] = useState<Column[]>(
        initialTrackingsColumnOrder
    );

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto min-h-screen">
                    <div className="flex flex-row items-center">
                        <span className="text-2xl font-bold">Inventory</span>
                        <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                            {orderCount?.inventory_trackings}
                        </span>
                    </div>

                    <div className="flex flex-row items-start space-x-4 py-4">
                        <ClientSearchBar
                            setData={setTrackings}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="inventory"
                            sub_page="units"
                        />
                        <ColumnOrderDropdown
                            columnOrder={trackingsColumnOrder}
                            setColumnOrder={setTrackingsColumnOrder}
                            localStorageKey="trackingsColumnOrder"
                            selectedOrderType={selectedOrderType}
                            initialColumnOrder={initialTrackingsColumnOrder}
                            buttonHeightClass="h-8"
                        />
                    </div>
                    {!loading ? (
                        <TrackingsTable
                            trackings={trackings}
                            results={trackingsResults}
                            trackingType="inventory"
                            sortLoading={sortLoading}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            handleRefetch={handleRefetch}
                            emptyTableText={
                                "You'll see the trackings that are en route to the warehouse here."
                            }
                            columnOrder={trackingsColumnOrder}
                        />
                    ) : (
                        <div className="flex flex-grow items-center pt-44 justify-center">
                            {loading ? (
                                <LoadingWheel />
                            ) : (
                                <span className="text-sm text-gray-500">
                                    User has no inbound orders
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default InventoryTrackingsPage;
