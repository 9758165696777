/** @format */

import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import DeleteTrackingModal from "../pages/admin/clients/client-data/trackings/deleteTrackingModal";
import LoadingWheel, { SortLoading } from "../components/loadingWheel";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { Column } from "./models";
import { Referral } from "../models/referral";
import { formatMoney } from "../utils/price/formatMoney";
import { ReferralStatusTag } from "../components/statusTags";

export const initialReferralsColumnOrder = [
    {
        key: "created_at",
        label: "Referral Date",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "referred_email",
        label: "Referred Email",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "referred_name",
        label: "Referred Name",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "credit_amount",
        label: "Credit Amount",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
];

interface ReferralsTableProps {
    referrals: Referral[];
    results: Results;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    emptyTableText: string;
    columnOrder?: Column[];
    handleApprove?: (referral_id: string) => Promise<void>;
}

function ReferralsTable({
    referrals,
    results,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    emptyTableText,
    columnOrder,
    handleApprove,
}: ReferralsTableProps) {
    const [searchParams] = useSearchParams();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTrackingId, setSelectedTrackingId] = useState<string | null>(
        ""
    );
    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const [approveLoadings, setApproveLoadings] = useState<string[]>([]);

    return (
        <div className="flex flex-col pt-3">
            {referrals && referrals.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {columnOrder &&
                                        columnOrder.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.label}
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {referrals.map((referral, index) => (
                                    <tr
                                        key={index}
                                        className="relative border-t border-gray-200 text-sm text-gray-500"
                                    >
                                        {columnOrder &&
                                            columnOrder.map(
                                                (header: Column) => (
                                                    <>
                                                        {header.key ===
                                                        "created_at" ? (
                                                            <td className="py-2.5 text-sm">
                                                                {convertDateToEST(
                                                                    String(
                                                                        referral[
                                                                            header.key as keyof Referral
                                                                        ]
                                                                    )
                                                                )}
                                                            </td>
                                                        ) : header.key ===
                                                          "credit_approved" ? (
                                                            <td>
                                                                <ReferralStatusTag
                                                                    credit_approved={Boolean(
                                                                        referral[
                                                                            header.key as keyof Referral
                                                                        ]
                                                                    )}
                                                                />
                                                            </td>
                                                        ) : header.key ===
                                                          "actions" ? (
                                                            <td className="text-right">
                                                                {!referral.credit_approved && (
                                                                    <button
                                                                        onClick={() => {
                                                                            setApproveLoadings(
                                                                                (
                                                                                    prev
                                                                                ) => [
                                                                                    ...prev,
                                                                                    referral.referral_id,
                                                                                ]
                                                                            );
                                                                            handleApprove &&
                                                                                handleApprove(
                                                                                    referral.referral_id
                                                                                );
                                                                        }}
                                                                        disabled={approveLoadings.includes(
                                                                            referral.referral_id
                                                                        )}
                                                                        className="text-xs text-white font-medium px-1.5 h-7 bg-accent rounded-md"
                                                                    >
                                                                        {!approveLoadings.includes(
                                                                            referral.referral_id
                                                                        ) ? (
                                                                            "Approve"
                                                                        ) : (
                                                                            <LoadingWheel
                                                                                color="white"
                                                                                size="xs"
                                                                            />
                                                                        )}
                                                                    </button>
                                                                )}
                                                            </td>
                                                        ) : header.key ===
                                                          "credit_amount" ? (
                                                            <td className="py-2.5 text-sm">
                                                                {formatMoney(
                                                                    Number(
                                                                        referral[
                                                                            header.key as keyof Referral
                                                                        ]
                                                                    )
                                                                )}
                                                            </td>
                                                        ) : (
                                                            <td
                                                                key={`${index}-${header.key}`}
                                                                className="py-2.5 text-sm"
                                                            >
                                                                {
                                                                    referral[
                                                                        header.key as keyof Referral
                                                                    ]
                                                                }
                                                            </td>
                                                        )}
                                                    </>
                                                )
                                            )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/referrals?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/referrals?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        {emptyTableText}
                    </span>
                </div>
            )}

            <DeleteTrackingModal
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                id={selectedTrackingId}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default ReferralsTable;
