/** @format */

import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { PiShippingContainer } from "react-icons/pi";
import { TbBus } from "react-icons/tb";
import { MdOutlineInventory2 } from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";
import { HiOutlineCreditCard } from "react-icons/hi2";
import Settings from "../settings";
import { ChevronLeftIcon, CircleStackIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { IconType } from "react-icons";
import { IoPeopleOutline } from "react-icons/io5";
import { ClientOrderCount } from "../../models/clients";
import { useClient } from "../../utils/client/clientContext";
import { VscReferences } from "react-icons/vsc";

export type OpenItems = {
    [key: string]: boolean;
};

export const adminNavigationPages = (
    orderCount: ClientOrderCount | null | undefined // Pass the count as an argument
) => [
    {
        name: "Dashboard",
        href: "/admin",
        icon: LuLayoutDashboard,
        current: true,
    },
    {
        name: "3PL Clients",
        icon: TbBus,
        current: false,
        children: [
            {
                name: "Clients",
                href: "/admin/clients",
                count: "",
                keepQueryParams: false,
            },
            {
                name: "Inbound",
                href: "/admin/inbound",
                count: orderCount?.inbound || "",
                keepQueryParams: true,
            },
            {
                name: "Inventory",
                href: "/admin/inventory",
                count: orderCount?.inventory || "",
                keepQueryParams: true,
            },
            {
                name: "Outbound",
                href: "/admin/outbound",
                count: orderCount?.outbound || "",
                keepQueryParams: true,
            },
            {
                name: "Billing",
                href: "/admin/billing",
                count: "",
                keepQueryParams: true,
            },
            {
                name: "Referrals",
                href: "/admin/referrals",
                count: "",
                keepQueryParams: true,
            },
            {
                name: "Settings",
                href: "/admin/settings",
                count: "",
                keepQueryParams: true,
            },
        ],
        href: "/admin/clients",
    },
    {
        name: "Employees",
        href: "/admin/employees",
        icon: IoPeopleOutline,
        current: true,
    },
];

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

const AdminSidebarIconWithPopup = ({
    item,
    currentPath,
    user_id,
}: {
    item: {
        name: string;
        href: string;
        icon: IconType;
        current: boolean;
        children?: { name: string; href: string }[];
    };
    currentPath: string;
    user_id: string | undefined | null;
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    return (
        <div
            className="relative flex items-center flex-col py-1.5 rounded-md hover:bg-gray-100"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <item.icon
                className="h-6 w-6 shrink-0 text-gray-600"
                onClick={() => navigate(item.href)}
            />
            <div
                className={`
            absolute left-full ml-0 top-0 bg-white text-gray-700 shadow-lg rounded-lg p-2 w-48
            transition-all duration-300 ease-in-out transform
            ${
                isHovered
                    ? "opacity-100 translate-x-0"
                    : "opacity-0 translate-x-2 pointer-events-none"
            }
          `}
            >
                <div className="flex flex-col space-y-1">
                    {item.children &&
                        item.children.map((subItem) => (
                            <a
                                href={
                                    user_id
                                        ? `${subItem.href}?user_id=${user_id}`
                                        : subItem.href
                                }
                                className={`hover:bg-gray-100 p-2 rounded-md cursor-pointer text-sm ${
                                    currentPath === subItem.href
                                        ? "bg-gray-100"
                                        : ""
                                }`}
                            >
                                {subItem.name}
                            </a>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default function AdminSideBar({
    user_id,
    orderCount,
}: {
    user_id?: string | null;
    orderCount?: ClientOrderCount | null;
}) {
    const {
        client,
        setClient,
        clientLoading,
        setUserId,
        orderCount: orderCounts,
    } = useClient();

    const location = useLocation();
    const navigate = useNavigate();
    const [currentPath, setCurrentPath] = useState("");
    const [isMinimized, setIsMinimized] = useState(() => {
        // Initialize state from localStorage, default to false if not set
        const saved = localStorage.getItem("sidebarMinimized");
        return saved !== null ? JSON.parse(saved) : false;
    });

    const [openItems, setOpenItems] = useState(() => {
        const saved = localStorage.getItem("sidebarOpenItems");
        return saved !== null ? JSON.parse(saved) : {};
    });

    useEffect(() => {
        const normalizedPath = window.location.pathname.replace(/\/$/, "");
        console.log(normalizedPath);
        setCurrentPath(normalizedPath);
    }, []);

    useEffect(() => {
        localStorage.setItem("sidebarMinimized", JSON.stringify(isMinimized));
    }, [isMinimized]);

    useEffect(() => {
        // Save open items state to localStorage
        localStorage.setItem("sidebarOpenItems", JSON.stringify(openItems));
    }, [openItems]);

    const toggleSidebar = () => {
        setIsMinimized(!isMinimized);
    };

    const toggleItem = (itemName: string) => {
        setOpenItems((prevOpenItems: OpenItems) => ({
            ...prevOpenItems,
            [itemName]: !prevOpenItems[itemName], // Toggle the item's state
        }));
    };

    const [navigationItems, setNavigationItems] = useState(
        adminNavigationPages(orderCount)
    );

    useEffect(() => {
        setNavigationItems(adminNavigationPages(orderCount));
    }, [orderCount]);

    const handleNavigation = (subItem: {
        name: string;
        href: string;
        count: string | number;
        keepQueryParams: boolean;
    }) => {
        if (!subItem.keepQueryParams) {
            setUserId(null);
        }
        navigate(
            user_id && subItem.keepQueryParams
                ? `${subItem.href}?user_id=${user_id}`
                : subItem.href
        );
    };

    return (
        <div className="hidden lg:block">
            <div className="flex flex-row">
                {/* Sidebar */}
                <div
                    className={`fixed z-20 top-0 h-screen pt-6 flex flex-col justify-between bg-white border-r border-gray-300 transition-all duration-300 ease-in-out ${
                        isMinimized ? "w-16" : "w-64"
                    }`}
                >
                    <div className="z-20">
                        <div
                            className={`flex ${
                                isMinimized
                                    ? "justify-center"
                                    : "justify-start pl-4"
                            }`}
                        >
                            <img
                                alt="logo"
                                src="/branding/logo-white.jpeg"
                                className={`${
                                    isMinimized ? "w-10" : "w-20"
                                } h-auto transition-all duration-300 cursor-pointer`}
                                onClick={() => navigate("/admin")}
                            />
                        </div>
                        <div className="pt-10"></div>
                        <nav
                            className={`flex flex-col mx-4 z-20 ${
                                isMinimized ? "items-center" : ""
                            }`}
                        >
                            <ul
                                role="list"
                                className="flex flex-1 flex-col gap-y-7 z-20"
                            >
                                <li>
                                    <ul
                                        role="list"
                                        className="-mx-2 space-y-1 z-20"
                                    >
                                        {navigationItems.map((item) => (
                                            <li key={item.name}>
                                                {!item.children ? (
                                                    <button
                                                        onClick={() =>
                                                            navigate(item.href)
                                                        }
                                                        className={classNames(
                                                            currentPath ===
                                                                item.href
                                                                ? "bg-gray-100 text-accent"
                                                                : "hover:bg-gray-100 text-gray-600",
                                                            "group z-20 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 items-center w-full"
                                                        )}
                                                    >
                                                        <item.icon
                                                            aria-hidden="true"
                                                            className="h-6 w-6 shrink-0"
                                                        />
                                                        {!isMinimized && (
                                                            <span className="text-sm font-semibold">
                                                                {item.name}
                                                            </span>
                                                        )}
                                                    </button>
                                                ) : (
                                                    <Disclosure as="div">
                                                        {!isMinimized ? (
                                                            <DisclosureButton
                                                                onClick={() => {
                                                                    toggleItem(
                                                                        item.name
                                                                    );
                                                                    navigate(
                                                                        item.href
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    item.current
                                                                        ? "bg-gray-100 text-accent"
                                                                        : "hover:bg-gray-100 text-gray-600",
                                                                    "group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6"
                                                                )}
                                                            >
                                                                <item.icon
                                                                    aria-hidden="true"
                                                                    className="h-6 w-6 shrink-0"
                                                                />
                                                                {!isMinimized && (
                                                                    <>
                                                                        {
                                                                            item.name
                                                                        }
                                                                        <ChevronRightIcon
                                                                            aria-hidden="true"
                                                                            className="ml-auto h-5 w-5 shrink-0 text-gray-700 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                                                                        />
                                                                    </>
                                                                )}
                                                            </DisclosureButton>
                                                        ) : (
                                                            <AdminSidebarIconWithPopup
                                                                item={item}
                                                                currentPath={
                                                                    currentPath
                                                                }
                                                                user_id={
                                                                    user_id
                                                                }
                                                            />
                                                        )}
                                                        {openItems[item.name] &&
                                                            !isMinimized && (
                                                                <ul className="mt-1 px-2">
                                                                    {item.children.map(
                                                                        (
                                                                            subItem
                                                                        ) => (
                                                                            <li
                                                                                key={
                                                                                    subItem.name
                                                                                }
                                                                            >
                                                                                <DisclosureButton
                                                                                    onClick={() =>
                                                                                        handleNavigation(
                                                                                            subItem
                                                                                        )
                                                                                    }
                                                                                    className={`
                                                                                ${
                                                                                    currentPath ===
                                                                                    subItem.href
                                                                                        ? "bg-gray-100"
                                                                                        : "hover:bg-gray-50"
                                                                                } flex w-full rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 justify-bewtween"
                                                                            `}
                                                                                >
                                                                                    {
                                                                                        subItem.name
                                                                                    }
                                                                                    {subItem.count &&
                                                                                        !isMinimized && (
                                                                                            <span
                                                                                                aria-hidden="true"
                                                                                                className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                                                                                            >
                                                                                                {subItem.count.toLocaleString()}
                                                                                            </span>
                                                                                        )}
                                                                                </DisclosureButton>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                    {/* </DisclosurePanel> */}
                                                                </ul>
                                                            )}
                                                    </Disclosure>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="flex flex-col justify-center">
                        <button
                            onClick={toggleSidebar}
                            className="p-2 mb-4 mx-auto bg-gray-100 rounded-full hover:bg-gray-200 transition-colors duration-200"
                        >
                            {isMinimized ? (
                                <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                            ) : (
                                <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
                            )}
                        </button>
                        <div
                            className={`pb-4 ${
                                isMinimized
                                    ? "justify-center flex"
                                    : "pl-4 pr-2"
                            } z-20`}
                        >
                            <Settings isMinimized={isMinimized} />
                        </div>
                    </div>
                </div>

                <div
                    className={`transition-all duration-300 ease-in-out ${
                        isMinimized ? "ml-16" : "ml-64"
                    }`}
                ></div>
            </div>
        </div>
    );
}
