/** @format */
import { IoMdQrScanner } from "react-icons/io";
import { useState } from "react";
import Client from "../../../../../models/clients";
import { getAdminType } from "../../../../../utils/adminTypes/actions";
import { MdOutlineDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { AddClientTrackingsModal } from "../../actions/addClientTrackingsModal";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import { approveShippingNamesRequest } from "../../../api/clientsApi";
import LoadingWheel from "../../../../../components/loadingWheel";

export default function ClientActions({
    client,
    setOpenEditClientModal,
    setOpenDeleteModal,
    handleClientRefetch,
}: {
    client: Client | null;
    setOpenEditClientModal: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleClientRefetch: () => void;
}) {
    const isOwner = getAdminType() === "owner";
    const { showNotification } = useNotification();

    const [openAddClientTrackingsModal, setOpenAddClientTrackingsModal] =
        useState(false);

    const [approveLoading, setApproveLoading] = useState(false);
    const handleApproveNamesRequest = async () => {
        setApproveLoading(true);
        const data = await approveShippingNamesRequest(client?.user_id || "");
        if (data.status === "success") {
            handleClientRefetch();
            showNotification("Approved shipping names request.", "", "success");
        } else {
            showNotification("Failed to to approve.", data.message, "error");
        }
        setApproveLoading(false);
    };

    return (
        <div className="flex flex-col justify-between pt-4 space-y-4">
            <div className="flex flex-row space-x-4 pt-6 sm:pt-0">
                <button
                    onClick={() => setOpenAddClientTrackingsModal(true)}
                    className="flex h-7 flex-row items-center text-xs px-2 border border-gray-400 shadow-sm rounded-md text-black"
                >
                    <IoMdQrScanner className="w-4 h-4" />{" "}
                    <span className="pl-1.5">Scan trackings</span>
                </button>
                <button
                    onClick={() => setOpenEditClientModal(true)}
                    className="flex h-7 flex-row items-center text-xs px-2 border border-gray-400 shadow-sm rounded-md text-black"
                >
                    <FaRegEdit className="w-4 h-4" />{" "}
                    <span className="pl-1.5">Edit client</span>
                </button>
                {isOwner ? (
                    <button
                        onClick={() => setOpenDeleteModal(true)}
                        className="flex h-7 flex-row items-center text-xs px-2 bg-red-600 rounded-md text-white"
                    >
                        <MdOutlineDelete className="w-4 h-4" />{" "}
                        <span className="pl-1.5">Delete client</span>
                    </button>
                ) : null}
            </div>

            {client?.requested_shipping_names_amount &&
            client?.requested_shipping_names_amount > 0 ? (
                <div>
                    <button
                        onClick={() => handleApproveNamesRequest()}
                        className="flex h-7 flex-row items-center text-xs px-2 shadow-sm bg-accent hover:bg-accenthighlight rounded-md text-white"
                    >
                        {!approveLoading ? (
                            <CheckCircleIcon className="w-4 h-4" />
                        ) : (
                            <LoadingWheel color="white" size="xs" />
                        )}
                        <span className="pl-1.5">
                            Approve {client.requested_shipping_names_amount}{" "}
                            Shipping Names Request
                        </span>
                    </button>
                </div>
            ) : (
                <></>
            )}

            <AddClientTrackingsModal
                user_id={client?.user_id || ""}
                openAddClientTrackingsModal={openAddClientTrackingsModal}
                setOpenAddClientTrackingsModal={setOpenAddClientTrackingsModal}
            />
        </div>
    );
}
