/** @format */

import { useSearchParams } from "react-router-dom";
import { TrackingArrived } from "../models/trackingArrived";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import { SortLoading } from "../components/loadingWheel";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { EmployeeClockEvent } from "../models/employees";
import { TrashIcon } from "@heroicons/react/24/outline";
import useCellSelection from "../hooks/useCellSelection";
import { useEffect, useState } from "react";
import { Column } from "./models";
import { formatMoney } from "../utils/price/formatMoney";

function calculateHoursWorked(
    clockIn: string | null,
    clockOut: string | null
): number {
    if (!clockIn || !clockOut) {
        return 0;
    }

    const clockInDate = new Date(clockIn);
    const clockOutDate = new Date(clockOut);
    const differenceInMilliseconds =
        clockOutDate.getTime() - clockInDate.getTime();
    const hoursWorked = differenceInMilliseconds / (1000 * 60 * 60);

    return parseFloat(hoursWorked.toFixed(2));
}

interface EmployeeClockEventsTableProps {
    clockEvents: EmployeeClockEvent[];
    results: Results;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    handleDelete: (event_id: string) => void;
    setTotalSum: React.Dispatch<React.SetStateAction<string>>;
}

function EmployeeClockEventsTable({
    clockEvents,
    results,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    handleDelete,
    setTotalSum,
}: EmployeeClockEventsTableProps) {
    const [searchParams] = useSearchParams();
    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const displayedHeaders: Column[] = [
        {
            key: "name",
            label: "Employee name",
            className: "sm:min-w-32 min-w-32",
            isSortable: false,
        },
        {
            key: "clock_in",
            label: "Clock in",
            className: "sm:min-w-32 min-w-32",
            isSortable: false,
        },
        {
            key: "clock_out",
            label: "Clock out",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
        },
        {
            key: "hours_worked",
            label: "Hours worked",
            className: "sm:min-w-32 min-w-28",
            isSortable: false,
            isSummable: true,
        },
        {
            key: "delete",
            label: "",
            className: "text-right",
            isSortable: false,
        },
    ];

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        if (displayedHeaders) {
            const key = displayedHeaders[columnIndex].key;
            if (key) return order[key];
            return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection(
        [],
        getCellValue,
        displayedHeaders
            ?.map((column, index) => (column.isSummable ? index : -1))
            .filter((index) => index !== -1) || []
    );

    useEffect(() => {
        const formattedTotal = sumSelectedQuantities
            .filter(({ sum }) => sum > 0)
            .map(({ columnIndex, sum }) =>
                displayedHeaders &&
                ["unit_cost", "list_price", "shipment_value"].includes(
                    displayedHeaders[columnIndex].key
                )
                    ? formatMoney(sum)
                    : sum.toString()
            )
            .join(", ");

        setTotalSum(formattedTotal);
    }, [sumSelectedQuantities]);

    return (
        <div className="flex flex-col pt-3">
            {clockEvents && clockEvents.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table
                            onMouseUp={handleMouseUp}
                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto"
                        >
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {displayedHeaders.map((header) => (
                                        <th
                                            key={header.key}
                                            onClick={() =>
                                                sortableColumns.includes(
                                                    header.key
                                                ) && toggleSort(header.key)
                                            }
                                            className={`group py-2 cursor-pointer ${
                                                sortableColumns.includes(
                                                    header.key
                                                )
                                                    ? "hover:bg-gray-100"
                                                    : ""
                                            } ${header.className}`}
                                        >
                                            {header.label}
                                            <ColumnSort
                                                header={header}
                                                sortConfig={sortConfig}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {clockEvents.map((event, rowIndex) => (
                                    <tr
                                        key={rowIndex}
                                        className="relative border-t border-gray-200 text-sm text-gray-500"
                                    >
                                        {displayedHeaders &&
                                            displayedHeaders.map(
                                                (header, colIndex) => (
                                                    <>
                                                        {header.key ===
                                                        "clock_in" ? (
                                                            <td className="py-2.5 sm:whitespace-nowrap">
                                                                {convertDateToEST(
                                                                    event.clock_in
                                                                )}
                                                            </td>
                                                        ) : header.key ===
                                                          "clock_out" ? (
                                                            <td className="py-2.5 sm:whitespace-nowrap">
                                                                {event.clock_out &&
                                                                    convertDateToEST(
                                                                        event.clock_out
                                                                    )}
                                                            </td>
                                                        ) : header.key ===
                                                          "delete" ? (
                                                            <td
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        event.event_id
                                                                    )
                                                                }
                                                                className="text-right"
                                                            >
                                                                <TrashIcon className="w-4 h-4 text-red-500 hover:text-red-400 cursor-pointer" />
                                                            </td>
                                                        ) : header.isSummable ? (
                                                            <td
                                                                key={`${rowIndex}-${header.key}`}
                                                                onMouseDown={() =>
                                                                    handleMouseDown(
                                                                        rowIndex,
                                                                        colIndex,
                                                                        clockEvents
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    handleMouseEnter(
                                                                        rowIndex,
                                                                        colIndex,
                                                                        clockEvents
                                                                    )
                                                                }
                                                                onClick={() =>
                                                                    handleSingleClick(
                                                                        rowIndex,
                                                                        colIndex,
                                                                        clockEvents
                                                                    )
                                                                }
                                                                className={`py-2.5 ${
                                                                    selectedCells.some(
                                                                        (
                                                                            cell
                                                                        ) =>
                                                                            cell.rowIndex ===
                                                                                rowIndex &&
                                                                            cell.columnIndex ===
                                                                                colIndex
                                                                    )
                                                                        ? "bg-blue-200 select-none"
                                                                        : "select-none"
                                                                }`}
                                                            >
                                                                {[
                                                                    "unit_cost",
                                                                    "list_price",
                                                                    "shipment_value",
                                                                ].includes(
                                                                    String(
                                                                        header.key
                                                                    )
                                                                ) ? (
                                                                    <>
                                                                        {formatMoney(
                                                                            Number(
                                                                                event[
                                                                                    header.key as keyof EmployeeClockEvent
                                                                                ]
                                                                            )
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {String(
                                                                            event[
                                                                                header.key as keyof EmployeeClockEvent
                                                                            ]
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                {String(
                                                                    event[
                                                                        header.key as keyof EmployeeClockEvent
                                                                    ]
                                                                )}
                                                            </td>
                                                        )}
                                                    </>
                                                )
                                            )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/admin/employees?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/admin/employees?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        No records available
                    </span>
                </div>
            )}
        </div>
    );
}

export default EmployeeClockEventsTable;
