/** @format */

import { useNavigate, useSearchParams } from "react-router-dom";
import Client from "../../../models/clients";
import React, { useState, useEffect } from "react";
import { fetchClients } from "./../api/clientsApi";
import LoadingWheel, { SortLoading } from "../../../components/loadingWheel";
import Results from "../../../models/results";
import ClientFilters from "./actions/clientFilters";
import { SuccessNotification } from "../../../components/statusNotifications";

import {
    calculateOffset,
    getOffset,
} from "../../../utils/pagination/calculateOffset";
import { PaginationResults } from "../../../utils/pagination/paginationResults";
import { PaginationLink } from "../../../utils/pagination/paginationLink";
import LimitSelector from "../../../utils/pagination/limitSelector";
import useScrollTranslation from "../../../hooks/useScrollTranslation";
import ColumnSort from "../../../utils/sort/columnSort";
import { sortableColumns } from "../../../hooks/useSort";
import ClientDrawer from "./clientDrawer";
import { useClient } from "../../../utils/client/clientContext";
import { ShippingStatusTag } from "../../../components/statusTags";

const tableHeaders = [
    {
        key: "full_name",
        label: "Full Name",
        className: "",
    },
    {
        key: "email",
        label: "Email",
        className: "",
    },
    {
        key: "inbound",
        label: "Inbound",
        className: "",
    },
    { key: "inventory", label: "Inventory", className: "" },
    { key: "outbound", label: "Outbound", className: "" },
    { key: "user_id", label: "Client ID", className: "" },
    { key: "details", label: "Details", className: "" },
    {
        key: "shipping_status",
        label: "Shipping Status",
        className: "pl-4",
        isSortable: false,
    },
];

export default function ClientsTable({
    clients,
    results,
    selectedClients,
    setSelectedClients,
    sortConfig,
    toggleSort,
    sortLoading,
}: {
    clients: Client[];
    results: Results;
    selectedClients: string[];
    setSelectedClients: React.Dispatch<React.SetStateAction<string[]>>;
    sortConfig: {
        key: string;
        order: string;
    };
    toggleSort: (key: string) => void;
    sortLoading: boolean;
}) {
    const navigate = useNavigate();

    const { client, setClient, clientLoading, user_id, setUserId } =
        useClient();

    const [searchParams, setSearchParams] = useSearchParams();

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedClients(clients.map((client) => client.user_id));
        } else {
            setSelectedClients([]);
        }
    };

    const handleCheckboxChange = (clientId: string) => {
        setSelectedClients((prevSelected) =>
            prevSelected.includes(clientId)
                ? prevSelected.filter((id) => id !== clientId)
                : [...prevSelected, clientId]
        );
    };

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const [openClientDrawer, setOpenClientDrawer] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState<string | null>(
        null
    );
    useEffect(() => {
        if (selectedClientId) {
            setOpenClientDrawer(true);
        }
    }, [selectedClientId]);

    useEffect(() => {
        if (!openClientDrawer) {
            setSelectedClientId(null);
        }
    }, [openClientDrawer]);

    const nav = (user_id: string) => {
        setUserId(user_id);
        navigate(`/admin/inbound?user_id=${user_id}`);
    };

    useEffect(() => {
        const client = searchParams.get("client");
        if (client && client !== "") {
            setSelectedClientId(client);
        }
    }, []);

    return (
        <div>
            {clients && clients.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    <th className="py-2">
                                        <input
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={handleSelectAll}
                                            checked={
                                                selectedClients.length ===
                                                    clients.length &&
                                                clients.length > 0
                                            }
                                        />
                                    </th>
                                    {tableHeaders.map((header) => (
                                        <th
                                            key={header.key}
                                            onClick={() =>
                                                sortableColumns.includes(
                                                    header.key
                                                ) && toggleSort(header.key)
                                            }
                                            className={`group py-2 cursor-pointer ${
                                                sortableColumns.includes(
                                                    header.key
                                                )
                                                    ? "hover:bg-gray-100"
                                                    : ""
                                            } ${header.className}`}
                                        >
                                            {header.label}
                                            <ColumnSort
                                                header={header}
                                                sortConfig={sortConfig}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {clients.map((client) => (
                                    <tr
                                        onClick={() => nav(client.user_id)}
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        <td className="py-3 flex items-center pr-2">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCheckboxChange(
                                                        client.user_id
                                                    );
                                                }}
                                                checked={selectedClients.includes(
                                                    client.user_id
                                                )}
                                            />
                                        </td>
                                        <td className="py-2.5">
                                            {client.first_name +
                                                " " +
                                                client.last_name}
                                        </td>
                                        <td className="">{client.email}</td>
                                        <td className="">
                                            {client.inbound_quantity}
                                        </td>
                                        <td className="">
                                            {client.inventory_quantity}
                                        </td>
                                        <td className="">
                                            {client.outbound_quantity}
                                        </td>
                                        <td className="">{client.user_id}</td>
                                        <td
                                            className="text-accent"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedClientId(
                                                    client.user_id
                                                );
                                            }}
                                        >
                                            View
                                        </td>
                                        <td className="pl-4">
                                            <ShippingStatusTag
                                                shipping_status={
                                                    client.shipping_status
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block flex-row">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/admin/clients?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/admin/clients?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    {false ? (
                        <LoadingWheel />
                    ) : (
                        <div className="flex flex-col items-center">
                            <span className="text-sm text-gray-700 pb-3">
                                No clients found with these filters.
                            </span>
                        </div>
                    )}
                </div>
            )}

            <ClientDrawer
                user_id={selectedClientId || ""}
                open={openClientDrawer}
                setOpen={setOpenClientDrawer}
                client={client}
                setClient={setClient}
            />
        </div>
    );
}
