/** @format */

import { useState } from "react";
import { useNotification } from "../../utils/notification/notificationContext";
import { sendClaimedDiscount } from "./api/contactApi";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { InputField } from "../../components/inputField";
import LoadingWheel from "../../components/loadingWheel";

export default function EmailPopup({
    show,
    onClose,
}: {
    show: any;
    onClose: any;
}) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false);

    const { showNotification } = useNotification();

    if (!show) {
        return null;
    }

    const handleSend = async () => {
        setLoading(true);
        const data = await sendClaimedDiscount(name, email, phone);
        if (data.status === "success") {
            onClose();
            showNotification(
                "Offer successfully claimed",
                "You will receive a follow up email shortly.",
                "success"
            );
        } else {
            console.log("failed to send email");
        }
        setLoading(false);
    };

    return (
        <div>
            {show && (
                <div
                    className={`fixed inset-0 flex justify-center z-50 items-center bg-black bg-opacity-50 transition-opacity duration-500 ${
                        show ? "opacity-100" : "opacity-0"
                    }`}
                >
                    <div className="bg-white w-96 p-6 rounded-lg shadow-lg text-center transition-transform transform duration-500 ease-in-out scale-100">
                        <div className="absolute top-4 right-4">
                            <button
                                type="button"
                                onClick={() => onClose()}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                />
                            </button>
                        </div>
                        <h2 className="text-xl font-semibold mb-4">
                            Wait! Don't leave yet
                        </h2>
                        <p className="mb-4 text-lg">
                            Get your first{" "}
                            <span className="text-accent font-medium">
                                100 units
                            </span>{" "}
                            fulfilled for{" "}
                            <span className="text-accent font-medium">
                                free
                            </span>
                        </p>
                        <div className="flex flex-col space-y-3">
                            <InputField
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <InputField
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <InputField
                                label="Phone number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-center space-x-4 w-full pt-10">
                            <button
                                onClick={() => handleSend()}
                                className="bg-accent w-full text-white py-2 px-4 rounded-lg hover:bg-accenthighlight"
                            >
                                {loading ? (
                                    <LoadingWheel color="white" />
                                ) : (
                                    <>Send</>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
