/** @format */

import { useEffect, useState } from "react";
import ClientSideBar from "../../../components/client/sideBar";
import Header from "../../../components/header";
import ReferralsTable, {
    initialReferralsColumnOrder,
} from "../../../tables/referralsTable";
import { Referral } from "../../../models/referral";
import Results from "../../../models/results";
import { useSort } from "../../../hooks/useSort";
import { fetchReferrals } from "./api/referralsApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import { formatInvoiceMoney } from "../../../utils/price/formatMoney";
import LoadingWheel from "../../../components/loadingWheel";
import CopyButton, { CopyWithTextButton } from "../../../components/copyButton";

interface ReferralCards {
    total_approved_credits: number;
    total_pending_credits: number;
    total_referrals: number;
    referral_code: string;
}

function ReferralsPage() {
    const { showNotification } = useNotification();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [referrals, setReferrals] = useState<Referral[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [referralCards, setReferralCards] = useState<ReferralCards | null>(
        null
    );

    const stats = [
        { name: "Total Referrals", stat: referralCards?.total_referrals },
        {
            name: "Total Approved Credits",
            stat: formatInvoiceMoney(referralCards?.total_approved_credits),
        },
        {
            name: "Total Pending Credits",
            stat: formatInvoiceMoney(referralCards?.total_pending_credits),
        },
    ];

    const [loading, setLoading] = useState(false);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const fetchData = async () => {
        const data = await fetchReferrals();
        if (data.status === "success") {
            setReferrals(data.data.referrals);
            setReferralCards(data.data.referral_cards);
            // setResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [refetchData]);

    return (
        <div className="flex flex-row">
            <ClientSideBar />

            <div className="flex flex-col flex-grow">
                <div className="sm:px-10 px-4">
                    <Header />
                    <div className="pt-10 flex flex-row justify-between pb-2">
                        <span className="text-2xl font-bold">Referrals</span>
                    </div>
                    {!loading ? (
                        <>
                            <span className="text-sm text-accent flex items-center">
                                {"https://dashboard.urvafreight.com/signup?referral_code=" +
                                    referralCards?.referral_code}
                                <CopyButton
                                    textToCopy={
                                        "https://dashboard.urvafreight.com/signup?referral_code=" +
                                        referralCards?.referral_code
                                    }
                                />
                            </span>
                            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3 pb-10">
                                {stats.map((item) => (
                                    <div
                                        key={item.name}
                                        className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                                    >
                                        <dt className="truncate text-sm font-medium text-gray-500">
                                            {item.name}
                                        </dt>
                                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                            {item.stat}
                                        </dd>
                                    </div>
                                ))}
                            </dl>

                            <ReferralsTable
                                referrals={referrals}
                                results={results}
                                sortLoading={sortLoading}
                                sortConfig={sortConfig}
                                toggleSort={toggleSort}
                                handleRefetch={handleRefetch}
                                emptyTableText={
                                    "You'll see your referrals here."
                                }
                                columnOrder={initialReferralsColumnOrder}
                            />
                        </>
                    ) : (
                        <div className="flex flex-grow items-center pt-44 justify-center">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ReferralsPage;
