/** @format */

import { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../../components/loadingWheel";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import { Order, ShipmentQuantities } from "../../../../../models/order";
import { fetchLabels } from "../../api/labelApi";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import InventoryTable from "../inventoryTable";
import { LabelModal } from "./labelModal";
import { UploadLabelModal } from "./uploadLabelModal";
import { Label } from "../../../../../models/label";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { requestOutboundShipment } from "../../api/inventoryApi";
import { InputField } from "../../../../../components/inputField";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const getFileExtension = (url: string): string => {
    return url.split(".").pop()?.split(/#|\?/)[0] || "";
};

const PdfViewer: React.FC<{ url: string }> = ({ url }) => {
    const [numPages, setNumPages] = useState<number | null>(null);

    return (
        <div>
            <div className="border border-gray-300 cursor-pointer shadow-sm h-44 w-36 overflow-hidden rounded-md hover:border-accent transition-all hover:scale-105">
                <Document
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    file={url}
                >
                    <Page pageNumber={1} height={200} width={120} />
                </Document>
            </div>
            <span className="text-xs">{numPages} pages</span>
        </div>
    );
};

export function RequestOutboundShipmentModal({
    selectedOrders,
    openRequestOutboundShipmentModal,
    setOpenRequestOutboundShipmentModal,
    handleRefetch,
}: {
    selectedOrders: Order[];
    openRequestOutboundShipmentModal: boolean;
    setOpenRequestOutboundShipmentModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [labels, setLabels] = useState<Label[]>([]);

    const [refetchLabelData, setRefetchLabelData] = useState<number>(0);
    const handleLabelRefetch = () => {
        setRefetchLabelData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchLabels();
            if (data.status === "success") {
                setLabels(data.data.labels);
            } else {
                showNotification(
                    "Failed to fetch labels",
                    data.message,
                    "error"
                );
            }
        };
        if (openRequestOutboundShipmentModal || refetchLabelData) {
            fetchData();
        }
    }, [openRequestOutboundShipmentModal, refetchLabelData]);

    const [selectedViewingLabel, setSelectedViewingLabel] =
        useState<Label | null>(null);

    const [openLabelModal, setOpenLabelModal] = useState(false);
    const [selectedPagesByLabel, setSelectedPagesByLabel] = useState<{
        [labelId: string]: number[];
    }>({});

    // Function to update selected pages for a particular label
    const updateSelectedPages = (labelId: string, selectedPages: number[]) => {
        setSelectedPagesByLabel((prevState) => ({
            ...prevState,
            [labelId]: selectedPages, // Update selected pages for the specific label
        }));
    };

    useEffect(() => {
        if (selectedViewingLabel) {
            setOpenLabelModal(true);
        }
    }, [selectedViewingLabel]);

    useEffect(() => {
        if (!openLabelModal) {
            setSelectedViewingLabel(null);
        }
    }, [openLabelModal]);

    const [openUploadLabelModal, setOpenUploadLabelModal] = useState(false);
    const [shipmentName, setShipmentName] = useState("");
    const [shipmentNotes, setShipmentNotes] = useState("");

    const [shipmentQuantities, setShipmentQuantities] =
        useState<ShipmentQuantities>({});

    const handleQuantityChange = (orderId: string, value: string) => {
        setShipmentQuantities((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const clearFields = () => {
        setShipmentQuantities({});
        setSelectedPagesByLabel({});
    };

    const handleRequest = async () => {
        setLoading(true);
        const order_ids = selectedOrders.map((order) => order.order_id);
        const data = await requestOutboundShipment(
            order_ids,
            shipmentQuantities,
            shipmentName,
            shipmentNotes,
            selectedPagesByLabel
        );
        if (data.status === "success") {
            setOpenRequestOutboundShipmentModal(false);
            showNotification("Requested outbound shipment", "", "success");
            handleRefetch();
            setSelectedPagesByLabel({});
        } else {
            showNotification("Failed to create request", data.message, "error");
        }
        setLoading(false);
    };

    return (
        <div>
            <Dialog
                open={openRequestOutboundShipmentModal}
                onClose={() => setOpenRequestOutboundShipmentModal(false)}
                className="relative z-20"
            >
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-20 w-screen">
                    <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-7xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="absolute right-0 top-0 pr-4 pt-4">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setOpenRequestOutboundShipmentModal(
                                            false
                                        )
                                    }
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="h-6 w-6"
                                    />
                                </button>
                            </div>

                            <DialogTitle
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900 pb-5"
                            >
                                Request shipment
                            </DialogTitle>
                            <div className="w-full h-px bg-gray-200"></div>

                            <div className="flex-1 overflow-y-auto mt-4 mb-4">
                                <InventoryTable
                                    inventoryOrders={selectedOrders}
                                    handleRefetch={handleRefetch}
                                    shipmentQuantities={shipmentQuantities}
                                    handleQuantityChange={handleQuantityChange}
                                />

                                <div className="pt-6"></div>

                                <div className="flex flex-row space-x-4">
                                    <div className="w-2/3">
                                        <InputField
                                            label="Shipment name"
                                            value={shipmentName}
                                            onChange={(e) =>
                                                setShipmentName(e.target.value)
                                            }
                                            placeholder=""
                                        />
                                    </div>
                                    <InputField
                                        label="Notes"
                                        value={shipmentNotes}
                                        onChange={(e) =>
                                            setShipmentNotes(e.target.value)
                                        }
                                        placeholder=""
                                    />
                                </div>

                                <div className="flex flex-row items-center justify-between pt-10">
                                    <div className="flex flex-row items-center">
                                        <h1 className="text-base font-semibold">
                                            Select Labels
                                            {" - "}
                                        </h1>
                                        <span className="text-sm text-accent font-medium">
                                            {""}
                                            {
                                                Object.values(
                                                    selectedPagesByLabel
                                                ).flat().length
                                            }
                                            {" pages selected"}
                                        </span>
                                    </div>
                                    <span
                                        onClick={() =>
                                            setOpenUploadLabelModal(true)
                                        }
                                        className="text-sm text-accent cursor-pointer inline-flex items-center gap-x-1 hover:text-accenthighlight"
                                    >
                                        <PlusCircleIcon className="h-4 w-4" />
                                        Upload label
                                    </span>
                                </div>
                                <div className="flex flex-row space-x-4 overflow-scroll">
                                    {labels.map((label, index) => (
                                        <div
                                            onClick={() =>
                                                setSelectedViewingLabel(label)
                                            }
                                            key={index}
                                            className="pl-2 pt-4"
                                        >
                                            {getFileExtension(
                                                label.public_url
                                            ) === "pdf" ? (
                                                <>
                                                    <PdfViewer
                                                        url={label.public_url}
                                                    />
                                                    <span className="text-sm text-accent font-semibold">
                                                        {selectedPagesByLabel[
                                                            label.label_id
                                                        ]
                                                            ? `${
                                                                  selectedPagesByLabel[
                                                                      label
                                                                          .label_id
                                                                  ].length
                                                              } ${" selected"}`
                                                            : ""}{" "}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="border border-gray-300 cursor-pointer shadow-sm h-44 w-36 overflow-hidden rounded-md hover:border-accent transition-all hover:scale-105">
                                                        <img
                                                            src={
                                                                label.public_url
                                                            }
                                                        />
                                                    </div>
                                                    <span className="text-sm text-accent font-semibold">
                                                        {selectedPagesByLabel[
                                                            label.label_id
                                                        ]
                                                            ? `${
                                                                  selectedPagesByLabel[
                                                                      label
                                                                          .label_id
                                                                  ].length
                                                              } ${" selected"}`
                                                            : ""}{" "}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Fixed buttons at the bottom */}
                            <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse justify-between">
                                <button
                                    onClick={() => handleRequest()}
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                                >
                                    {loading ? (
                                        <span className="pr-2 items-center flex flex-row justify-center">
                                            <LoadingWheel
                                                size="small"
                                                color="white"
                                            />
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    Request shipment
                                </button>
                                <div className="space-x-2">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            setOpenRequestOutboundShipmentModal(
                                                false
                                            )
                                        }
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => clearFields()}
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    >
                                        Clear all fields
                                    </button>
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            <LabelModal
                selectedViewingLabel={selectedViewingLabel}
                openLabelModal={openLabelModal}
                setOpenLabelModal={setOpenLabelModal}
                selectedPages={
                    selectedPagesByLabel[
                        selectedViewingLabel?.label_id || ""
                    ] || []
                }
                updateSelectedPages={(pages) =>
                    selectedViewingLabel &&
                    updateSelectedPages(selectedViewingLabel.label_id, pages)
                }
                handleLabelRefetch={handleLabelRefetch}
            />

            <UploadLabelModal
                openUploadLabelModal={openUploadLabelModal}
                setOpenUploadLabelModal={setOpenUploadLabelModal}
                handleLabelRefetch={handleLabelRefetch}
            />
        </div>
    );
}
