/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../components/loadingWheel";
import { useState } from "react";
import { closeInvoicePeriod } from "../../api/billingApi";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { InputField } from "../../../../components/inputField";

function CloseOpenInvoiceModal({
    user_id,
    openViewOpenInvoiceModal,
    setOpenViewOpenInvoiceModal,
    invoice_id,
    handleRefetch,
}: {
    user_id: string;
    openViewOpenInvoiceModal: boolean;
    setOpenViewOpenInvoiceModal: React.Dispatch<React.SetStateAction<boolean>>;
    invoice_id: string | undefined;
    handleRefetch: () => void;
}) {
    const [loading, setLoading] = useState(false);

    const { showNotification } = useNotification();

    const handleCloseInvoicePeriod = async () => {
        setLoading(true);
        const data = await closeInvoicePeriod(user_id, invoice_id);
        if (data.status === "success") {
            setOpenViewOpenInvoiceModal(false);
            showNotification("Closed invoice period", "", "success");
            handleRefetch();
        } else {
            showNotification(
                "Failed to close invoice period",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openViewOpenInvoiceModal}
            onClose={() => setOpenViewOpenInvoiceModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-2/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-2/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenViewOpenInvoiceModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Confirm closing invoice
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4">
                            <p className="text-sm text-gray-600">
                                The invoice will be closed and automatically
                                emailed to the client.
                            </p>
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                disabled={loading}
                                onClick={() => handleCloseInvoicePeriod()}
                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                            >
                                {loading ? (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                ) : (
                                    <></>
                                )}
                                Close invoice
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenViewOpenInvoiceModal(false)
                                }
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default CloseOpenInvoiceModal;
