/** @format */

import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import Header from "../../components/website/header";
import Footer from "../../components/website/footer";
import { useState } from "react";
import { sendContactRequest } from "./api/contactApi";
import { useNotification } from "../../utils/notification/notificationContext";
import LoadingWheel from "../../components/loadingWheel";
import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const shipmentSizes = [
    { id: 1, name: "0-499" },
    { id: 2, name: "500-999" },
    { id: 3, name: "1,000-1,999" },
    { id: 4, name: "2,000+" },
];

export default function RequestQuote({
    disable,
    title = "Request a Quote",
    subTitle = "Looking for cost-effective solutions? Request a quote today and see how we can deliver value to your business.",
}: {
    disable?: () => void;
    title?: string;
    subTitle?: string;
}) {
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();
    const [fullName, setFullName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");

    const [selectedShipmentSize, setSelectedShipmentSize] = useState(
        shipmentSizes[0]
    );

    const handleContact = async () => {
        disable && disable();
        setLoading(true);
        const data = await sendContactRequest(
            fullName,
            companyName,
            email,
            phoneNumber,
            message,
            selectedShipmentSize.name
        );
        if (data.status === "success") {
            showNotification("Succesfully sent contact request", "", "success");
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
    };

    return (
        <div id="request-quote" className="relative isolate bg-white">
            <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                <div className="relative px-6 pt-36 sm:pt-40 lg:static lg:px-8 lg:h-screen">
                    <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4">
                            {title}
                        </h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            {subTitle}
                        </p>
                        <dl className="mt-5 space-y-4 text-base leading-7 text-gray-600">
                            <div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Email</span>
                                    <EnvelopeIcon
                                        aria-hidden="true"
                                        className="h-7 w-6 text-gray-400"
                                    />
                                </dt>
                                <dd>
                                    <a
                                        href="mailto:contact@urvafreight.com"
                                        className="hover:text-gray-900"
                                    >
                                        contact@urvafreight.com
                                    </a>
                                </dd>
                            </div>
                            <div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Email</span>
                                    <PhoneIcon
                                        aria-hidden="true"
                                        className="h-7 w-6 text-gray-400"
                                    />
                                </dt>
                                <dd>
                                    <a
                                        href="mailto:contact@urvafreight.com"
                                        className="hover:text-gray-900"
                                    >
                                        (302) 685-2116
                                    </a>
                                </dd>
                            </div>
                        </dl>

                        <div className="pt-10"></div>
                        <div className="w-full border border-gray-200 sm:px-8 px-4 py-4 rounded-md">
                            <h1 className="text-xl font-semibold">
                                We promise
                            </h1>
                            <div className="grid grid-cols-2 sm:text-sm text-xs text-gray-800 pt-3 gap-y-4">
                                <p>• $0 Setup</p>
                                <p>• $0 Software</p>
                                <p>• $0 Receiving</p>
                                <p>• No MOQ</p>
                                <p>• 24-Hour Turnaround</p>
                                <p>•⁠ Sales Tax-Free Address</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-6 pt-16 sm:pt-36 lg:px-8 lg:h-screen">
                    <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                        <div className="grid grid-cols-2 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label
                                    htmlFor="first-name"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="full-name"
                                        name="full-name"
                                        type="text"
                                        autoComplete="given-name"
                                        value={fullName}
                                        onChange={(e) =>
                                            setFullName(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="">
                                <label
                                    htmlFor="company-name"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Company Name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        value={companyName}
                                        onChange={(e) =>
                                            setCompanyName(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2 col-span-2">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Email
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="phone-number"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Phone number
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="phone-number"
                                        name="phone-number"
                                        type="tel"
                                        autoComplete="tel"
                                        value={phoneNumber}
                                        onChange={(e) =>
                                            setPhoneNumber(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <Listbox
                                    value={selectedShipmentSize}
                                    onChange={setSelectedShipmentSize}
                                >
                                    <Label className="block text-sm font-semibold leading-6 text-gray-900">
                                        Units / month
                                    </Label>
                                    <div className="relative mt-2">
                                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                                            <span className="block truncate">
                                                {selectedShipmentSize.name}
                                            </span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon
                                                    aria-hidden="true"
                                                    className="h-5 w-5 text-gray-400"
                                                />
                                            </span>
                                        </ListboxButton>

                                        <ListboxOptions
                                            transition
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                        >
                                            {shipmentSizes.map(
                                                (shipmentSize) => (
                                                    <ListboxOption
                                                        key={shipmentSize.id}
                                                        value={shipmentSize}
                                                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-accent data-[focus]:text-white"
                                                    >
                                                        <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                                            {shipmentSize.name}
                                                        </span>

                                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-accent group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                            <CheckIcon
                                                                aria-hidden="true"
                                                                className="h-5 w-5"
                                                            />
                                                        </span>
                                                    </ListboxOption>
                                                )
                                            )}
                                        </ListboxOptions>
                                    </div>
                                </Listbox>
                            </div>
                            <div className="sm:col-span-2 col-span-2">
                                <label
                                    htmlFor="message"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Tell us about your business
                                </label>
                                <div className="mt-2.5">
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows={4}
                                        value={message}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        defaultValue={""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex justify-end">
                            <button
                                onClick={handleContact}
                                className="rounded-md bg-accent w-20 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                            >
                                {!loading ? (
                                    <>Send</>
                                ) : (
                                    <LoadingWheel size="small" color="white" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
