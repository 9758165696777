/** @format */

import { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../components/loadingWheel";
import { fetchInboundOrder, updateInboundOrder } from "../api/inboundApi";
import DeleteOrderModal from "./deleteOrderModal";
import {
    formatMoney,
    removeDollarSign,
} from "../../../../utils/price/formatMoney";
import {
    InputField,
    InputNumberField,
} from "../../../../components/inputField";
import dayjs, { Dayjs } from "dayjs";
import {
    convertToDayjsFromString,
    formatDateFromObject,
} from "../../../../utils/date/date_formatter";
import SingleCalendar from "../../../../components/singleCalendar";
import { useNotification } from "../../../../utils/notification/notificationContext";
import MultiTrackingInput from "../../../../components/multipleTrackingInput";
import { Order } from "../../../../models/order";
import FulfilledBySelector from "../../../../components/fulfilledBySelector";
import { capitalizeFirstLetter } from "../../../../utils/strings/string";

const fulfilledByOptions = [
    { id: 2, value: "Amazon" },
    { id: 3, value: "Merchant" },
];

export function EditOrderModal({
    inboundId,
    openEditModal,
    setOpenEditModal,
    handleRefetch,
}: {
    inboundId: string | undefined;
    openEditModal: boolean;
    setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [fetchingLoading, setFetchingLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [inboundOrder, setInboundOrder] = useState<Order | null>(null);

    const [fulfilledBy, setFulfilledBy] = useState<{
        id: number;
        value: string;
    }>({ id: 1, value: "-- select an option --" });

    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
    const [retailer, setRetailer] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [itemName, setItemName] = useState("");
    const [color, setColor] = useState("");
    const [brandSKU, setBrandSKU] = useState("");
    const [size, setSize] = useState("");
    const [quantity, setQuantity] = useState("");
    const [asin, setAsin] = useState("");
    const [amazonSKU, setAmazonSKU] = useState("");
    const [cogs, setCogs] = useState("");
    const [listPrice, setListPrice] = useState("");
    const [notes, setNotes] = useState("");
    const [trackingNumbers, setTrackingNumbers] = useState<string[]>([]);

    const [missingFields, setMissingFields] = useState(false);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const canSubmit = () => {
        if (
            retailer === "" ||
            orderNumber === "" ||
            itemName === "" ||
            color === "" ||
            size === "" ||
            quantity === "" ||
            cogs === ""
        ) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (openEditModal) {
            setFetchingLoading(true);
            fetchInboundOrder(inboundId, setInboundOrder, setFetchingLoading);
        } else {
            setOpenDeleteModal(false);
        }
    }, [openEditModal]);

    useEffect(() => {
        if (inboundOrder) {
            setSelectedDate(convertToDayjsFromString(inboundOrder?.date));
            setRetailer(inboundOrder?.retailer);
            setOrderNumber(inboundOrder?.order_number);
            setItemName(inboundOrder?.item_name);
            setColor(inboundOrder?.color);
            setBrandSKU(inboundOrder?.brand_sku);
            setSize(inboundOrder?.size);
            setQuantity(String(inboundOrder?.quantity));
            setAsin(inboundOrder?.asin);
            setAmazonSKU(inboundOrder?.amazon_sku);
            setCogs(formatMoney(inboundOrder?.unit_cost) || "");
            setListPrice(formatMoney(inboundOrder?.list_price) || "");
            setNotes(inboundOrder?.user_notes);

            if (inboundOrder?.fulfilled_by) {
                // Find the option that matches the fulfilled_by value
                const matchingOption = fulfilledByOptions.find(
                    (option) =>
                        option.value ===
                        capitalizeFirstLetter(inboundOrder.fulfilled_by)
                );
                if (matchingOption) {
                    setFulfilledBy(matchingOption);
                }
            }

            if (inboundOrder?.trackings.length > 0) {
                console.log(inboundOrder.trackings);
                setTrackingNumbers(
                    inboundOrder?.trackings?.map((t) => t.tracking) || []
                );
            } else {
                setTrackingNumbers([]);
            }
        }
    }, [inboundOrder]);

    const handleEditOrder = async () => {
        setLoading(true);
        const { success, message } = await updateInboundOrder(
            inboundId,
            fulfilledBy.value.toLowerCase(),
            formatDateFromObject(selectedDate),
            retailer,
            orderNumber,
            itemName,
            color,
            brandSKU,
            size,
            quantity,
            asin,
            amazonSKU,
            cogs,
            listPrice,
            notes,
            trackingNumbers
        );
        if (success) {
            handleRefetch();
            setOpenEditModal(false);
            showNotification("Your order has been edited", "", "success");
        } else {
            showNotification("An error occurred", message, "error");
        }
        setLoading(false);
    };

    return (
        <div>
            <Dialog
                open={openEditModal}
                onClose={() => setOpenEditModal(false)}
                className="relative z-20"
            >
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-20 w-screen">
                    <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-2xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="absolute right-0 top-0 pr-4 pt-4">
                                <button
                                    type="button"
                                    onClick={() => setOpenEditModal(false)}
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="h-6 w-6"
                                    />
                                </button>
                            </div>

                            <DialogTitle
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900 pb-5"
                            >
                                Edit shipment
                                {missingFields ? (
                                    <span className="text-red-500 pl-5">
                                        Missing fields, fill required fields.
                                    </span>
                                ) : (
                                    <></>
                                )}
                            </DialogTitle>
                            <div className="w-full h-px bg-gray-200"></div>

                            <div className="flex-1 overflow-y-auto mt-4 mb-4">
                                {!fetchingLoading ? (
                                    <div>
                                        <div>
                                            <span className="font-medium text-sm">
                                                Fulfilled by
                                            </span>
                                            <FulfilledBySelector
                                                selected={fulfilledBy}
                                                setSelected={setFulfilledBy}
                                                options={fulfilledByOptions}
                                            />
                                        </div>
                                        <div className="flex flex-row space-x-10 pt-4">
                                            <div className="space-y-4 w-full -mt-0.5">
                                                <SingleCalendar
                                                    selectedDate={selectedDate}
                                                    setSelectedDate={
                                                        setSelectedDate
                                                    }
                                                />
                                                <InputField
                                                    label="Item name"
                                                    value={itemName}
                                                    onChange={(e) =>
                                                        setItemName(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder=""
                                                />
                                                <InputField
                                                    label="Order #"
                                                    value={orderNumber}
                                                    onChange={(e) =>
                                                        setOrderNumber(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder=""
                                                />
                                                <InputField
                                                    label="Size"
                                                    value={size}
                                                    onChange={(e) =>
                                                        setSize(e.target.value)
                                                    }
                                                    placeholder=""
                                                />
                                                <InputField
                                                    label="Brand SKU"
                                                    value={brandSKU}
                                                    onChange={(e) =>
                                                        setBrandSKU(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder=""
                                                    optional={true}
                                                />
                                                <InputField
                                                    label="ASIN"
                                                    value={asin}
                                                    onChange={(e) =>
                                                        setAsin(e.target.value)
                                                    }
                                                    placeholder=""
                                                    optional={true}
                                                />
                                                <MultiTrackingInput
                                                    trackingNumbers={
                                                        trackingNumbers
                                                    }
                                                    setTrackingNumbers={
                                                        setTrackingNumbers
                                                    }
                                                />
                                            </div>
                                            <div className="space-y-4 w-full">
                                                <InputField
                                                    label="Retailer"
                                                    value={retailer}
                                                    onChange={(e) =>
                                                        setRetailer(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder=""
                                                />
                                                <InputNumberField
                                                    label="Quantity"
                                                    value={quantity}
                                                    onChange={(e) =>
                                                        setQuantity(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder=""
                                                />
                                                <InputField
                                                    label="Color"
                                                    value={color}
                                                    onChange={(e) =>
                                                        setColor(e.target.value)
                                                    }
                                                    placeholder=""
                                                />
                                                <InputField
                                                    label="Unit cost"
                                                    value={cogs}
                                                    onChange={(e) =>
                                                        setCogs(e.target.value)
                                                    }
                                                    placeholder=""
                                                    extraText={`Order total: $${(
                                                        Number(quantity) *
                                                        Number(
                                                            removeDollarSign(
                                                                cogs
                                                            )
                                                        )
                                                    ).toLocaleString("en-US", {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}`}
                                                />
                                                <InputField
                                                    label="List price"
                                                    value={listPrice}
                                                    onChange={(e) =>
                                                        setListPrice(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder=""
                                                    optional={true}
                                                />
                                                <InputField
                                                    label="Amazon SKU"
                                                    value={amazonSKU}
                                                    onChange={(e) =>
                                                        setAmazonSKU(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder=""
                                                    optional={true}
                                                />
                                                <InputField
                                                    label="Notes"
                                                    value={notes}
                                                    onChange={(e) =>
                                                        setNotes(e.target.value)
                                                    }
                                                    placeholder=""
                                                    optional={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center pt-20">
                                        <LoadingWheel />
                                    </div>
                                )}
                            </div>

                            {/* Fixed buttons at the bottom */}
                            <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse justify-between">
                                <button
                                    type="button"
                                    onClick={
                                        canSubmit()
                                            ? () => handleEditOrder()
                                            : () => setMissingFields(true)
                                    }
                                    className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                                >
                                    {loading ? (
                                        <span className="pr-2 items-center flex flex-row justify-center">
                                            <LoadingWheel
                                                size="small"
                                                color="white"
                                            />
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    Save
                                </button>
                                <div className="space-x-2">
                                    <button
                                        type="button"
                                        onClick={() => setOpenDeleteModal(true)}
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-redd-400 sm:mt-0 sm:w-auto"
                                    >
                                        Delete
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setOpenEditModal(false)}
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            <DeleteOrderModal
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                order_ids={[inboundId || ""]}
                setOpenEditModal={setOpenEditModal}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}
