/** @format */

import { EnvelopeIcon } from "@heroicons/react/24/outline";
import Header from "../../components/website/header";
import Footer from "../../components/website/footer";
import { useState } from "react";
import { sendContactRequest } from "./api/contactApi";
import { useNotification } from "../../utils/notification/notificationContext";
import LoadingWheel from "../../components/loadingWheel";
import RequestQuote from "./requestQuote";

export default function ContactPage() {
    return (
        <div>
            <Header />

            <div id="request-quote"></div>
            <RequestQuote
                title="Contact Us"
                subTitle="Looking for cost-effective solutions? Contact us today and see how we can deliver value to your business."
            />

            <Footer />
        </div>
    );
}
