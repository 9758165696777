/** @format */
import Cookies from "js-cookie";
import { debounce } from "lodash";
import { Order } from "../../../../models/order";
import Shipment from "../../../../models/shipments";
import { TrackingArrived } from "../../../../models/trackingArrived";
import { buildUrl } from "../../../../utils/url/buildUrl";
import { AdminNotification } from "../../../../models/adminDashboard";

export const fetchDashboardData = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/statistics`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
            }
        );

        const data = await response.json();
        return data;

    } catch (error) {
        return { data: {status: "failed"} }
    }
};

export const updateTax = async (
    tax_rate: string,
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/account/tax`,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({tax_rate})
            }
        );

        const data = await response.json()
        return data;
        
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}

export const removeClientNotification = async (notificationId: string, deleteAll?: boolean) => {
    try {
        const jsonBody = deleteAll ? {deleteAll: true} : {}
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/notifications/` +
                notificationId,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
                body: JSON.stringify(jsonBody)
            }
        );

        const data = await response.json();

        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error) } };
    }
};

export const fetchClientNotifications = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/notifications`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error) } };
    }
};

export const searchData = debounce(async (
    searchText: string,
    user_id: string | undefined,
    page: string | null,
    sub_page: string | null,
    setData: React.Dispatch<React.SetStateAction<Order[]>> | React.Dispatch<React.SetStateAction<Shipment[]>> | React.Dispatch<React.SetStateAction<TrackingArrived[]>> | React.Dispatch<React.SetStateAction<AdminNotification[]>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    handleRefetch: () => void
) => {
    if (searchText.trim() === "") {
        handleRefetch()
        return;
    }
    try {
        setLoading(true)

        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/search`;
        const params = {
            searchText: searchText,
            user_id: user_id ? user_id : "",
            page: page,
            sub_page: sub_page
        };

        const url = buildUrl(baseUrl, params);

        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + Cookies.get("jwt")
                }
            }
        );
        const data = await response.json();
        if (data.status === "success") {
            setData(data.data)
        }
    } catch (error) {

    } finally {
        setLoading(false)
    }
}, 300);