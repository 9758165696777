/** @format */

import { useEffect, useState } from "react";
import Header from "../../../components/header";
import { Invoice } from "../../../models/invoices";
import { fetchInvoices } from "./api/billingApi";
import InvoicesTable from "./invoicesTable";
import Results from "../../../models/results";
import LoadingWheel from "../../../components/loadingWheel";
import { useNotification } from "../../../utils/notification/notificationContext";
import ClientSideBar from "../../../components/client/sideBar";

function BillingPage() {
    const { showNotification } = useNotification();
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const data = await fetchInvoices();
            if (data.status === "success") {
                setInvoices(data.data.invoices);
                setResults(data.data.results);
            } else {
                showNotification(
                    "Failed to fetch invoices.",
                    data.message,
                    "error"
                );
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow">
                <div className="sm:px-10 px-4">
                    <Header />
                    <div className="pt-10 flex flex-col">
                        <span className="text-2xl font-bold">Billing</span>
                    </div>

                    <div className="pt-10"></div>
                    {!loading ? (
                        <InvoicesTable invoices={invoices} results={results} />
                    ) : (
                        <div className="flex flex-grow items-center pt-44 justify-center">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BillingPage;
