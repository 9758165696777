/** @format */

"use client";

import { useState, useEffect } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import {
    Bars3Icon,
    ChevronRightIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { HiChevronRight } from "react-icons/hi";

const navigation = [
    { name: "Home", href: "/" },
    { name: "Order Fulfillment", href: "/order-fulfillment" },
    // { name: "Ecommerce Management", href: "/ecommerce-management" },
    { name: "Pricing", href: "/pricing" },
    { name: "Contact", href: "/contact" },
];

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        // Set the current path when the component mounts
        setCurrentPath(window.location.pathname);
        if (window.location.pathname !== "/") {
            setIsScrolled(true);
        }
    }, []);

    const [isScrolled, setIsScrolled] = useState(false);
    const handleScroll = () => {
        if (window.location.pathname === "/") {
            setIsScrolled(window.scrollY > 200);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        // <div className="w-full fixed top-0 z-30">
        <div
            className={`w-full fixed top-0 z-30 transition-all duration-300 ${
                isScrolled ? "bg-white text-gray-900" : "bg-white text-gray-900"
            }`}
        >
            <header className="">
                <nav
                    aria-label="Global"
                    className="flex items-center justify-between p-6 md:px-8 h-20"
                >
                    <div className="flex md:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">URVA Freight</span>
                            <img
                                alt=""
                                // src="/branding/logo-nobg.png"
                                src="/branding/urva-white-name.jpeg"
                                // className="h-8 w-auto"
                                className="h-auto w-14"
                            />
                        </a>
                    </div>
                    <div className="flex md:hidden">
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 ${
                                isScrolled ? "text-black" : "text-gray-900"
                            }`}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="hidden md:flex md:gap-x-12">
                        {navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className={`text-sm font-semibold leading-6 relative ${
                                    currentPath === item.href
                                        ? "text-accent"
                                        : `${
                                              currentPath === "/"
                                                  ? ""
                                                  : "text-gray-900"
                                          } hover:text-accent group`
                                }`}
                            >
                                {item.name}
                                <span className="absolute left-1/2 -bottom-1 h-0.5 w-0 bg-accent transition-all duration-300 ease-out group-hover:w-full group-hover:left-0"></span>
                            </a>
                        ))}
                    </div>
                    <div className="hidden md:flex md:flex-1 md:justify-end">
                        <a
                            href="https://dashboard.urvafreight.com/login"
                            className="group rounded-lg bg-accent items-center inline-flex pl-3 pr-2 h-9 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight transition-all duration-300"
                        >
                            Dashboard
                            <HiChevronRight className="w-6 h-6 font-bold transition-transform duration-300 transform group-hover:translate-x-0.5" />
                        </a>
                    </div>
                </nav>
                <Dialog
                    open={mobileMenuOpen}
                    onClose={setMobileMenuOpen}
                    className="md:hidden"
                >
                    <div className="fixed inset-0 z-50" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">URVA Freight</span>
                                <img
                                    alt=""
                                    src="/branding/logo-white.jpeg"
                                    className="h-8 w-auto cursor-pointer"
                                />
                            </a>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 ${
                                                currentPath === item.href
                                                    ? "text-accent"
                                                    : "text-gray-900 hover:text-accent"
                                            }`}
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    <a
                                        href="https://dashboard.urvafreight.com/login"
                                        className="-mx-3 block rounded-lg bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight leading-7"
                                    >
                                        Log in
                                    </a>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>
        </div>
    );
}
