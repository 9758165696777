/** @format */

import { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { Order } from "../../models/order";
import Shipment from "../../models/shipments";
import { useLocation, useParams } from "react-router-dom";
import { TrackingArrived } from "../../models/trackingArrived";
import { searchData } from "../../pages/client/dashboard/api/dashboardApi";
import { AdminNotification } from "../../models/adminDashboard";

interface HeaderSearchBarProps {
    setData:
        | React.Dispatch<React.SetStateAction<Order[]>>
        | React.Dispatch<React.SetStateAction<Shipment[]>>
        | React.Dispatch<React.SetStateAction<TrackingArrived[]>>
        | React.Dispatch<React.SetStateAction<AdminNotification[]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
    page: string;
    sub_page: string;
}

export default function ClientSearchBar({
    setData,
    setLoading,
    handleRefetch,
    page,
    sub_page,
}: HeaderSearchBarProps) {
    // const { user_id } = useParams();
    // const [searchTerm, setSearchTerm] = useState<string>("");
    // const inputRef = useRef<HTMLInputElement>(null);

    // const location = useLocation();

    // useEffect(() => {
    //     const queryParams = new URLSearchParams(location.search);
    //     searchData(
    //         searchTerm,
    //         user_id,
    //         page,
    //         sub_page,
    //         setData,
    //         setLoading,
    //         handleRefetch
    //     );
    //     return () => searchData.cancel();
    // }, [searchTerm]);

    const { user_id } = useParams();
    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>(null);

    const location = useLocation();

    useEffect(() => {
        // Determine search term based on keywords or inputValue
        const searchTerm =
            keywords.length > 0 ? keywords.join(";") : inputValue;

        searchData(
            searchTerm,
            user_id,
            page,
            sub_page,
            setData,
            setLoading,
            handleRefetch
        );

        return () => searchData.cancel();
    }, [keywords, inputValue]); // Add inputValue to dependencies

    // Function to add a keyword when the user presses Enter
    const handleAddKeyword = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && inputValue.trim()) {
            setKeywords([...keywords, inputValue.trim()]);
            setInputValue("");
        }
    };

    // Function to remove a keyword
    const handleRemoveKeyword = (index: number) => {
        setKeywords(keywords.filter((_, i) => i !== index));
    };

    return (
        <div className="w-full">
            <input
                placeholder="Search"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="outline-none px-0 py-0 text-xs border border-gray-300 h-8 w-full rounded-md pl-2 focus:border-gray-400 focus:ring-0"
                ref={inputRef}
                onKeyDown={handleAddKeyword}
            />
            <div className="flex flex-wrap mt-2">
                {keywords.map((keyword, index) => (
                    <div
                        key={index}
                        className="bg-gray-100 px-2 py-1 rounded-md flex items-center space-x-2 m-1"
                    >
                        <span className="text-sm">{keyword}</span>
                        <button
                            onClick={() => handleRemoveKeyword(index)}
                            className="text-red-500 text-xs"
                        >
                            x
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}
